import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../utils/firebase"; // Import Firebase Firestore
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import * as XLSX from "xlsx"; // Import XLSX library for parsing Excel files

interface Item {
  product: string;
  size: string;
  supplier: string;

  stockShouldHave: number;
  category?: string;
  details?: string;
  costPrice?: number;
  sellingPrice?: number;
  hidden?: boolean;
}

const Items: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [suppliers, setSuppliers] = useState<string[]>([]);
  const [supplierDescriptions, setSupplierDescriptions] = useState<{
    [key: string]: string;
  }>({});
  const [editedItems, setEditedItems] = useState<{
    [key: string]: Partial<Item>;
  }>({});
  const [editedSuppliers, setEditedSuppliers] = useState<{
    [key: string]: string;
  }>({});
  const [isAddSupplierModalOpen, setAddSupplierModalOpen] = useState(false);
  const [newSupplierName, setNewSupplierName] = useState("");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isAddItemModalOpen, setAddItemModalOpen] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [isEditItemModalOpen, setEditItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [isEditDescriptionModalOpen, setEditDescriptionModalOpen] =
    useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    fetchSuppliersFromFirestore();
    fetchCategoriesFromFirestore();
  }, []);

  useEffect(() => {
    if (suppliers.length > 0) {
      fetchItemsFromFirestore(suppliers[0]);
    }
  }, [suppliers]);

  const fetchSuppliersFromFirestore = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "suppliers"));
      const supplierNames: string[] = [];
      const descriptions: { [key: string]: string } = {};

      for (const docSnapshot of querySnapshot.docs) {
        const supplierName = docSnapshot.id;
        supplierNames.push(supplierName);

        // Fetch description if exists
        const supplierData = docSnapshot.data();
        if (supplierData.description) {
          descriptions[supplierName] = supplierData.description;
        }
      }

      // Sort supplier names alphabetically
      supplierNames.sort((a, b) => a.localeCompare(b));

      setSuppliers(supplierNames);
      setSupplierDescriptions(descriptions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching suppliers from Firestore: ", error);
      setLoading(false);
    }
  };

  const fetchCategoriesFromFirestore = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "categories"));
      const fetchedCategories: string[] = [];
      querySnapshot.forEach((docSnapshot) => {
        fetchedCategories.push(docSnapshot.id);
      });
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Error fetching categories from Firestore: ", error);
    }
  };

  const fetchItemsFromFirestore = async (supplier: string) => {
    try {
      setLoading(true);
      const itemsSnapshot = await getDocs(
        collection(db, "suppliers", supplier, "items")
      );
      const supplierData: Item[] = [];
      itemsSnapshot.forEach((itemDoc) => {
        supplierData.push(itemDoc.data() as Item);
      });

      setItems(supplierData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching items from Firestore: ", error);
      setLoading(false);
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
    fetchItemsFromFirestore(suppliers[newValue]);
  };

  const handleAddItem = () => {
    setNewItemName("");
    setAddItemModalOpen(true);
  };

  const handleEditItem = (product: string, field: string, value: any) => {
    setEditedItems((prev) => ({
      ...prev,
      [product]: { ...prev[product], [field]: value },
    }));
  };

  const handleEditSupplier = (index: number, value: string) => {
    setEditedSuppliers((prev) => ({
      ...prev,
      [suppliers[index]]: value,
    }));
  };

  const handleSave = async () => {
    try {
      for (const product in editedItems) {
        const item = {
          ...items.find((i) => i.product === product),
          ...editedItems[product],
        } as Item;
        console.log("Saving edited item: ", item);

        const supplierDoc = doc(db, "suppliers", item.supplier);
        const itemDoc = doc(collection(supplierDoc, "items"), item.product);
        await setDoc(itemDoc, item);

        if (item.category) {
          await addCategoryToFirestore(item.category);
        }
      }
      for (const oldSupplier in editedSuppliers) {
        const newSupplier = editedSuppliers[oldSupplier];
        if (newSupplier !== oldSupplier) {
          const oldSupplierDoc = doc(db, "suppliers", oldSupplier);
          const newSupplierDoc = doc(db, "suppliers", newSupplier);

          // Add name field to the new supplier document
          await setDoc(newSupplierDoc, { name: newSupplier });

          // Move items to new supplier
          const itemsSnapshot = await getDocs(
            collection(oldSupplierDoc, "items")
          );
          const batch = itemsSnapshot.docs.map((itemDoc) => {
            const itemData = itemDoc.data();
            return setDoc(
              doc(collection(newSupplierDoc, "items"), itemDoc.id),
              {
                ...itemData,
                supplier: newSupplier,
              }
            );
          });

          await Promise.all(batch);
          await deleteDoc(oldSupplierDoc);
        } else {
          const supplierDoc = doc(db, "suppliers", newSupplier);
          // Add name field to the existing supplier document
          await setDoc(supplierDoc, { name: newSupplier }, { merge: true });
        }
      }

      Swal.fire({
        title: "Success!",
        text: "Items and suppliers updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      setEditedItems({});
      setEditedSuppliers({});
      fetchSuppliersFromFirestore(); // Refresh data
      fetchCategoriesFromFirestore(); // Refresh categories
    } catch (error) {
      console.error("Error updating items and suppliers: ", error);
    }
  };

  const addCategoryToFirestore = async (category: string) => {
    const existingCategory = categories.find(
      (cat) => cat.toLowerCase() === category.toLowerCase()
    );
    if (!existingCategory) {
      try {
        const categoryDoc = doc(db, "categories", category.toLowerCase());
        await setDoc(categoryDoc, { name: category });
        fetchCategoriesFromFirestore(); // Refresh categories after adding
      } catch (error) {
        console.error("Error adding new category: ", error);
      }
    }
  };

  const handleAddSupplier = async () => {
    setAddSupplierModalOpen(true);
  };

  const handleAddSupplierConfirm = async () => {
    if (newSupplierName) {
      try {
        const supplierDoc = doc(db, "suppliers", newSupplierName);
        await setDoc(supplierDoc, { name: newSupplierName });
        setNewSupplierName("");
        setAddSupplierModalOpen(false);
        fetchSuppliersFromFirestore();
      } catch (error) {
        console.error("Error adding new supplier: ", error);
      }
    }
  };

  const handleAddItemConfirm = async () => {
    const supplier = suppliers[tabIndex];
    if (supplier && newItemName) {
      const newItem: Item = {
        product: newItemName,
        size: "",
        supplier,
        costPrice: 0,
        stockShouldHave: 0,
        category: "",
      };
      try {
        const supplierDoc = doc(db, "suppliers", supplier);
        const itemDoc = doc(collection(supplierDoc, "items"), newItem.product);
        await setDoc(itemDoc, newItem);
        setAddItemModalOpen(false);
        fetchItemsFromFirestore(supplier);
      } catch (error) {
        console.error("Error adding new item: ", error);
      }
    }
  };

  const handleDeleteItem = async (item: Item) => {
    const confirmDelete = window.confirm(`Delete item ${item.product}?`);
    if (confirmDelete) {
      try {
        const supplierDoc = doc(db, "suppliers", item.supplier);
        const itemDoc = doc(collection(supplierDoc, "items"), item.product);
        await deleteDoc(itemDoc);
        fetchItemsFromFirestore(item.supplier);
      } catch (error) {
        console.error("Error deleting item: ", error);
      }
    }
  };

  const handleOpenEditItemModal = (item: Item) => {
    setSelectedItem(item);
    setEditItemModalOpen(true);
  };

  const handleCloseEditItemModal = () => {
    setEditItemModalOpen(false);
    setSelectedItem(null);
  };

  const handleEditItemModalChange = (field: string, value: any) => {
    if (selectedItem) {
      setSelectedItem({ ...selectedItem, [field]: value });
    }
  };
  const handleSaveEditItem = async () => {
    if (selectedItem) {
      try {
        const originalSupplier = items.find(
          (item) => item.product === selectedItem.product
        )?.supplier;
        const newSupplier = selectedItem.supplier;

        if (
          originalSupplier &&
          newSupplier &&
          originalSupplier !== newSupplier
        ) {
          const originalSupplierDoc = doc(db, "suppliers", originalSupplier);
          const newSupplierDoc = doc(db, "suppliers", newSupplier);
          const originalItemDoc = doc(
            collection(originalSupplierDoc, "items"),
            selectedItem.product
          );
          const newItemDoc = doc(
            collection(newSupplierDoc, "items"),
            selectedItem.product
          );

          // Delete the item from the original supplier's collection
          await deleteDoc(originalItemDoc);

          // Add the item to the new supplier's collection
          await setDoc(newItemDoc, selectedItem);

          // Add new supplier if it doesn't exist
          await setDoc(newSupplierDoc, { name: newSupplier }, { merge: true });
        } else {
          const supplierDoc = doc(db, "suppliers", selectedItem.supplier);
          const itemDoc = doc(
            collection(supplierDoc, "items"),
            selectedItem.product
          );
          await setDoc(itemDoc, selectedItem);
        }

        if (selectedItem.category) {
          await addCategoryToFirestore(selectedItem.category);
        }

        setEditItemModalOpen(false);
        fetchItemsFromFirestore(selectedItem.supplier);
      } catch (error) {
        console.error("Error saving edited item: ", error);
      }
    }
  };

  const handleOpenEditDescriptionModal = () => {
    const supplier = suppliers[tabIndex];
    setNewDescription(supplierDescriptions[supplier] || "");
    setEditDescriptionModalOpen(true);
  };

  const handleSaveDescription = async () => {
    const supplier = suppliers[tabIndex];
    try {
      const supplierDoc = doc(db, "suppliers", supplier);
      await setDoc(
        supplierDoc,
        { description: newDescription },
        { merge: true }
      );
      setEditDescriptionModalOpen(false);
      fetchSuppliersFromFirestore();
    } catch (error) {
      console.error("Error saving supplier description: ", error);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        console.log("Excel data: ", jsonData);

        // Process the JSON data
        processExcelData(jsonData as string[][]);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const processExcelData = async (data: string[][]) => {
    const headers = data[0];
    const rows = data.slice(1);

    const items: Item[] = rows.map((row) => {
      const item: Item = {
        product: row[headers.indexOf("Product")] || "Unknown",
        size: row[headers.indexOf("Size")] || "Unknown",
        supplier: row[headers.indexOf("Supplier")] || "Unknown",
        costPrice: parseFloat(row[headers.indexOf("Cost price")]) || 0,
        stockShouldHave: parseInt(row[headers.indexOf("Order wanted")]) || 0,
        category: row[headers.indexOf("Category")] || "Unknown",
        details: "",
        sellingPrice: 0,
        hidden: false,
      };

      return item;
    });

    for (const item of items) {
      await uploadItemToFirestore(item);
    }

    Swal.fire({
      title: "Success!",
      text: "Items uploaded successfully.",
      icon: "success",
      confirmButtonText: "OK",
    });

    fetchSuppliersFromFirestore(); // Refresh data
    fetchCategoriesFromFirestore(); // Refresh categories
  };

  const uploadItemToFirestore = async (item: Item) => {
    try {
      const supplierDoc = doc(db, "suppliers", item.supplier);
      await setDoc(supplierDoc, { name: item.supplier }, { merge: true }); // Add or update supplier name
      const itemDoc = doc(collection(supplierDoc, "items"), item.product);
      await setDoc(itemDoc, item);

      if (item.category) {
        await addCategoryToFirestore(item.category);
      }
    } catch (error) {
      console.error("Error uploading item to Firestore: ", error);
    }
  };

  return (
    <main className="flex-1 w-full max-w-4xl mx-auto p-4">
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Typography variant="h4" gutterBottom>
            Items List
          </Typography>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{ flexGrow: 1 }}
            >
              {suppliers.map((supplier, index) => (
                <Tab
                  label={
                    editMode ? (
                      <TextField
                        value={editedSuppliers[supplier] || supplier}
                        onChange={(e) =>
                          handleEditSupplier(index, e.target.value)
                        }
                        fullWidth
                      />
                    ) : (
                      supplier
                    )
                  }
                  key={index}
                />
              ))}
            </Tabs>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
            {supplierDescriptions[suppliers[tabIndex]] && (
              <Typography variant="body1" color="textSecondary" mb={1}>
                {supplierDescriptions[suppliers[tabIndex]]}
              </Typography>
            )}

            <Button
              variant="contained"
              style={{ color: "black", backgroundColor: "#f78da7" }}
              onClick={handleOpenEditDescriptionModal}
              sx={{ mb: 2 }}
            >
              Edit Supplier Description
            </Button>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems="center"
            mt={2}
          >
            <Button
              variant="contained"
              style={{ color: "black", backgroundColor: "#f78da7" }}
              onClick={handleAddItem}
              sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 2 } }}
            >
              Add Item
            </Button>
            <Button
              variant="contained"
              style={{ color: "black", backgroundColor: "#f78da7" }}
              onClick={handleAddSupplier}
              sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 2 } }}
            >
              Add Supplier
            </Button>
            <Button
              variant="contained"
              component="label"
              style={{ color: "black", backgroundColor: "#f78da7" }}
              sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 2 } }}
            >
              Upload Excel
              <input
                type="file"
                hidden
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
            </Button>
            <FormControlLabel
              control={
                <Switch
                  checked={editMode}
                  onChange={(e) => setEditMode(e.target.checked)}
                  name="editMode"
                  color="primary"
                />
              }
              label="Edit Mode"
            />
            {editMode && (
              <Button
                variant="contained"
                style={{ color: "black", backgroundColor: "#f78da7" }}
                onClick={handleSave}
                sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 2 } }}
              >
                Save Changes
              </Button>
            )}
          </Box>
          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 800 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Cost Price</TableCell>
                    <TableCell>Stock Should Have</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Details</TableCell>
                    <TableCell>Selling Price</TableCell>
                    <TableCell>Hidden</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items
                    .filter((item) => item.supplier === suppliers[tabIndex])
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Button onClick={() => handleOpenEditItemModal(item)}>
                            {item.product}
                          </Button>
                        </TableCell>
                        <TableCell>
                          {editMode ? (
                            <TextField
                              value={
                                editedItems[item.product]?.size ?? item.size
                              }
                              onChange={(e) =>
                                handleEditItem(
                                  item.product,
                                  "size",
                                  e.target.value
                                )
                              }
                              fullWidth
                              sx={{ width: 100 }}
                            />
                          ) : (
                            item.size
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode ? (
                            <TextField
                              type="number"
                              value={
                                editedItems[item.product]?.costPrice ??
                                item.costPrice
                              }
                              onChange={(e) =>
                                handleEditItem(
                                  item.product,
                                  "costPrice",
                                  parseFloat(e.target.value)
                                )
                              }
                              fullWidth
                              sx={{ width: 120 }}
                            />
                          ) : (
                            (item.costPrice ?? 0).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode ? (
                            <TextField
                              type="number"
                              value={
                                editedItems[item.product]?.stockShouldHave ??
                                item.stockShouldHave
                              }
                              onChange={(e) =>
                                handleEditItem(
                                  item.product,
                                  "stockShouldHave",
                                  parseFloat(e.target.value)
                                )
                              }
                              fullWidth
                              sx={{ width: 150 }}
                            />
                          ) : (
                            item.stockShouldHave
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode ? (
                            <TextField
                              value={
                                editedItems[item.product]?.category ??
                                item.category
                              }
                              onChange={(e) =>
                                handleEditItem(
                                  item.product,
                                  "category",
                                  e.target.value
                                )
                              }
                              fullWidth
                              sx={{ width: 120 }}
                            />
                          ) : (
                            item.category
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode ? (
                            <TextField
                              value={
                                editedItems[item.product]?.details ??
                                item.details
                              }
                              onChange={(e) =>
                                handleEditItem(
                                  item.product,
                                  "details",
                                  e.target.value
                                )
                              }
                              fullWidth
                              sx={{ width: 150 }}
                            />
                          ) : (
                            item.details
                          )}
                        </TableCell>

                        <TableCell>
                          {editMode ? (
                            <TextField
                              type="number"
                              value={
                                editedItems[item.product]?.sellingPrice ??
                                item.sellingPrice
                              }
                              onChange={(e) =>
                                handleEditItem(
                                  item.product,
                                  "sellingPrice",
                                  parseFloat(e.target.value)
                                )
                              }
                              fullWidth
                              sx={{ width: 120 }}
                            />
                          ) : (
                            item.sellingPrice
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    editedItems[item.product]?.hidden ??
                                    item.hidden ??
                                    false
                                  }
                                  onChange={(e) =>
                                    handleEditItem(
                                      item.product,
                                      "hidden",
                                      e.target.checked
                                    )
                                  }
                                />
                              }
                              label=""
                            />
                          ) : item.hidden ? (
                            "Yes"
                          ) : (
                            "No"
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleDeleteItem(item)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
      <Dialog
        open={isAddSupplierModalOpen}
        onClose={() => setAddSupplierModalOpen(false)}
      >
        <DialogTitle>Add Supplier</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Supplier Name"
            type="text"
            fullWidth
            value={newSupplierName}
            onChange={(e) => setNewSupplierName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAddSupplierModalOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleAddSupplierConfirm} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isAddItemModalOpen}
        onClose={() => setAddItemModalOpen(false)}
      >
        <DialogTitle>Add Item</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Item Name"
            type="text"
            fullWidth
            value={newItemName}
            onChange={(e) => setNewItemName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddItemModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddItemConfirm} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {selectedItem && (
        <Dialog
          open={isEditItemModalOpen}
          onClose={handleCloseEditItemModal}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Edit Item</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Product Name"
              type="text"
              fullWidth
              value={selectedItem.product}
              onChange={(e) =>
                handleEditItemModalChange("product", e.target.value)
              }
            />
            <TextField
              margin="dense"
              label="Size"
              type="text"
              fullWidth
              value={selectedItem.size}
              onChange={(e) =>
                handleEditItemModalChange("size", e.target.value)
              }
            />
            <TextField
              margin="dense"
              label="Cost"
              type="number"
              fullWidth
              value={selectedItem.costPrice}
              onChange={(e) =>
                handleEditItemModalChange(
                  "costPrice",
                  parseFloat(e.target.value)
                )
              }
            />
            <TextField
              margin="dense"
              label="Stock Should Have"
              type="number"
              fullWidth
              value={selectedItem.stockShouldHave}
              onChange={(e) =>
                handleEditItemModalChange(
                  "stockShouldHave",
                  parseFloat(e.target.value)
                )
              }
            />
            <TextField
              margin="dense"
              label="Category"
              type="text"
              fullWidth
              value={selectedItem.category}
              onChange={(e) =>
                handleEditItemModalChange("category", e.target.value)
              }
            />
            <TextField
              margin="dense"
              label="Details"
              type="text"
              fullWidth
              value={selectedItem.details}
              onChange={(e) =>
                handleEditItemModalChange("details", e.target.value)
              }
            />
            <TextField
              margin="dense"
              label="Selling Price"
              type="number"
              fullWidth
              value={selectedItem.sellingPrice}
              onChange={(e) =>
                handleEditItemModalChange(
                  "sellingPrice",
                  parseFloat(e.target.value)
                )
              }
            />
            <TextField
              margin="dense"
              label="Supplier"
              select
              fullWidth
              value={selectedItem.supplier}
              onChange={(e) =>
                handleEditItemModalChange("supplier", e.target.value)
              }
              SelectProps={{
                native: true,
              }}
            >
              {suppliers.map((supplier) => (
                <option key={supplier} value={supplier}>
                  {supplier}
                </option>
              ))}
            </TextField>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedItem.hidden || false}
                  onChange={(e) =>
                    handleEditItemModalChange("hidden", e.target.checked)
                  }
                />
              }
              label="Hidden"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditItemModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveEditItem} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog
        open={isEditDescriptionModalOpen}
        onClose={() => setEditDescriptionModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit Supplier Description</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setEditDescriptionModalOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSaveDescription} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default Items;
