import React from "react";
import { Link } from "react-router-dom";
import { useUserRole } from "../contexts/UserRoleContext";

const Home: React.FC = () => {
  const { role, firstName } = useUserRole();
  const isAdmin = role === "admin";
  const isManager = role === "manager";
  const isEmployee = role === "employee";

  const generalLinks = [
    {
      to: "/product-orders",
      label: "Purchase Orders",
      visible: isAdmin || isManager || isEmployee,
    },
    {
      to: "/orders",
      label: "Purchase Order Dashboard",
      visible: isAdmin,
    },
    {
      to: "/ordersoverview",
      label: "Purchase Orders Overview",
      visible: isAdmin,
    },
    {
      to: "/eod",
      label: "End of Day",
      visible: isAdmin || isManager || isEmployee,
    },
    {
      to: "/eoddashboard",
      label: "EOD Dashboard",
      visible: isAdmin,
    },
    {
      to: "/roster",
      label: "Roster",
      visible: isAdmin || isManager || isEmployee,
    },
    {
      to: "/tips",
      label: "Tips",
      visible: isAdmin || isManager || isEmployee,
    },
    {
      to: "/tips-dashboard",
      label: "Tips Dashboard",
      visible: isAdmin,
    },
    {
      to: "/employees",
      label: "Employee List",
      visible: isAdmin || isManager,
    },
    {
      to: "/items",
      label: "Suppliers & Items",
      visible: isAdmin,
    },
    {
      to: "/overview",
      label: "Overview",
      visible: isAdmin,
    },
    {
      to: "/calendar",
      label: "Calendar",
      visible: isAdmin,
    },
  ];

  const filteredLinks = generalLinks.filter((link) => link.visible);

  return (
    <main className="relative flex justify-center items-center h-screen bg-gray-100">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover opacity-50"
      >
        <source src="assets/salumeria.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="relative text-center mt-8 px-4 z-10">
        <img
          src="assets/SalumeriaGardens.png"
          alt="Salumeria Logo"
          className="mx-auto mb-4 w-60 h-auto sm:w-60"
        />
        {firstName && (
          <h2 className="text-2xl mb-4 text-gray-800">Hello, {firstName}!</h2>
        )}{" "}
        <h1 className="text-4xl font-bold mb-4 text-gray-800">
          Welcome to the Salumeria Gardens Staff Portal
        </h1>
        <p className="mb-8 text-gray-800"></p>
        <div className="grid grid-cols-2 gap-4 mb-16">
          {filteredLinks.map((link) => (
            <Link
              key={link.to}
              to={link.to}
              className="bg-salumeria text-white px-4 py-2 rounded hover:bg-salumeria-light transition-colors duration-200 text-center"
            >
              {link.label}
            </Link>
          ))}
        </div>
      </div>
    </main>
  );
};

export default Home;
