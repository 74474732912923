import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../utils/firebase"; // Adjust the import path as necessary
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
} from "firebase/firestore";
import Swal from "sweetalert2";
interface Employee {
  id: string;
  firstName: string;
  role: string;
  // Add other fields as needed
}
const alwaysSelected = ["Bar", "Pablo", "Max"];

const TipsForm: React.FC = () => {
  const [shiftDate, setShiftDate] = useState<string>(
    new Date().toISOString().substr(0, 10)
  );
  const [shiftCloser, setShiftCloser] = useState<string>("");
  const [totalTips, setTotalTips] = useState<string>("");
  const [selectedPeople, setSelectedPeople] =
    useState<string[]>(alwaysSelected);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [individualTips, setIndividualTips] = useState<
    { name: string; amount: number }[]
  >([]);
  const [people, setPeople] = useState<any[]>([]);
  const [newPerson, setNewPerson] = useState<string>("");
  const [shiftClosers, setShiftClosers] = useState<string[]>([]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
      } else {
        setUserEmail(null);
      }
    });

    // Fetch employees from Firestore
    fetchEmployees();

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const fetchEmployees = async () => {
    const db = getFirestore();
    const usersQuery = query(collection(db, "users"));
    const querySnapshot = await getDocs(usersQuery);
    const employeesList: any[] = [];
    const closers: string[] = [];

    querySnapshot.forEach((doc) => {
      const employee = { id: doc.id, ...doc.data() } as Employee;
      employeesList.push(employee);
      if (
        ["admin", "manager", "shiftleader"].includes(
          employee.role.toLowerCase()
        ) &&
        employee.firstName !== "Paul"
      ) {
        closers.push(employee.firstName);
      }
    });

    setPeople(employeesList);
    setShiftClosers(closers);
    console.log(employeesList);
  };

  const handlePersonToggle = (person: string) => {
    setSelectedPeople((prevSelected) =>
      prevSelected.includes(person)
        ? prevSelected.filter((p) => p !== person)
        : [...prevSelected, person]
    );
  };

  useEffect(() => {
    if (selectedPeople.length > 0) {
      calculateIndividualTips();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeople, totalTips]);

  const calculateIndividualTips = () => {
    const tipAmount = parseFloat(totalTips) || 0;
    const tipPerPerson = tipAmount / selectedPeople.length;
    const tips = selectedPeople.map((person) => ({
      name: person,
      amount: Number(tipPerPerson.toFixed(2)),
    }));
    setIndividualTips(tips);
  };

  const handleAddNewPerson = () => {
    if (newPerson.trim() !== "") {
      setPeople((prevPeople) => [...prevPeople, { firstName: newPerson }]);
      setNewPerson("");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const tipAmount = parseFloat(totalTips);
    if (
      !userEmail ||
      !shiftDate ||
      !shiftCloser ||
      isNaN(tipAmount) ||
      tipAmount <= 0 ||
      selectedPeople.length === 0
    ) {
      Swal.fire(
        "Error",
        "Please fill all required fields and ensure total tips is greater than 0.",
        "error"
      );
      return;
    }

    const tipsData = {
      shiftDate,
      shiftCloser,
      totalTips: tipAmount,
      individualTips,
      userEmail,
      timestamp: new Date(),
    };

    try {
      const docRef = await addDoc(collection(db, "tips"), tipsData);
      console.log("Document written with ID: ", docRef.id);
      Swal.fire("Success", "Tips have been submitted successfully!", "success");
      // Clear all inputs
      setShiftDate(new Date().toISOString().substr(0, 10));
      setShiftCloser("");
      setTotalTips("");
      setSelectedPeople(alwaysSelected);
      setIndividualTips([]);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <main className="flex flex-col items-center p-4 min-h-screen bg-gray-100 relative">
      <h1 className="text-2xl font-bold mb-4">Tips Form</h1>
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded-lg shadow-md w-full max-w-lg"
      >
        <div className="mb-4">
          <label htmlFor="shiftDate" className="block text-gray-700">
            Shift Date
          </label>
          <input
            type="date"
            id="shiftDate"
            value={shiftDate}
            onChange={(e) => setShiftDate(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="shiftCloser" className="block text-gray-700">
            Shift Closer
          </label>
          <select
            id="shiftCloser"
            value={shiftCloser}
            onChange={(e) => setShiftCloser(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="" disabled>
              Select a closer
            </option>
            {shiftClosers.map((person) => (
              <option key={person} value={person}>
                {person}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="totalTips" className="block text-gray-700">
            Total Tips on Shift
          </label>
          <input
            type="number"
            id="totalTips"
            value={totalTips}
            onChange={(e) => setTotalTips(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Select People Working
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            {people
              .filter(
                (person) =>
                  !alwaysSelected.includes(person.firstName) &&
                  !(
                    person.firstName === "Pablo&Max" ||
                    person.firstName === "Paul"
                  )
              )
              .map((person) => (
                <div key={person.id} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={person.firstName}
                    checked={selectedPeople.includes(person.firstName)}
                    onChange={() => handlePersonToggle(person.firstName)}
                    className="mr-2"
                  />
                  <label htmlFor={person.firstName} className="text-gray-700">
                    {person.firstName}
                  </label>
                </div>
              ))}
            <div className="flex items-center mb-2">
              <input
                type="text"
                value={newPerson}
                onChange={(e) => setNewPerson(e.target.value)}
                className="mr-2 p-2 border rounded w-full"
                placeholder="Add new person"
              />
              <button
                type="button"
                onClick={handleAddNewPerson}
                className="bg-salumeria text-white px-4 py-2 rounded hover:bg-salumeria-light"
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-salumeria text-white px-4 py-2 rounded hover:bg-salumeria-light"
        >
          Submit
        </button>
      </form>
    </main>
  );
};

export default TipsForm;
