import React, { useState, useEffect } from "react";
import { db } from "../utils/firebase"; // Adjust the import path as necessary
import { collection, getDocs } from "firebase/firestore";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
} from "@mui/material";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

interface EODData {
  id: string;
  shiftDate: string;
  totalCash: string;
  totalCards: string;
  zRead: string; // Ensure this is included to fetch Zread value
  drinksCount: string;
  drinksValue: string;
  pizzasCount: string;
  pizzasValue: string;
  startersCount: string;
  startersValue: string;
  dessertsCount: string;
  dessertsValue: string;
}

const Overview: React.FC = () => {
  const [eodData, setEodData] = useState<EODData[]>([]);
  const [totalRevenue, setTotalRevenue] = useState<number>(0);
  const [totalItemsSold, setTotalItemsSold] = useState<number>(0);
  const [revenueTrend, setRevenueTrend] = useState<number>(0);
  const [itemsSoldTrend, setItemsSoldTrend] = useState<number>(0);
  const [averageDailyRevenue, setAverageDailyRevenue] = useState<number>(0);
  const [averageDailyDrinks, setAverageDailyDrinks] = useState<number>(0);
  const [averageDailyPizzas, setAverageDailyPizzas] = useState<number>(0);
  const [averageDailyStarters, setAverageDailyStarters] = useState<number>(0);
  const [averageDailyDesserts, setAverageDailyDesserts] = useState<number>(0);
  const [cumulativeMonthlyRevenue, setCumulativeMonthlyRevenue] =
    useState<number>(0);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "eod_reports"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as EODData[];

      // Filter data based on selected date range
      const filteredData = data.filter((d) => {
        const date = new Date(d.shiftDate);
        return date >= dateRange[0].startDate && date <= dateRange[0].endDate;
      });

      setEodData(filteredData);

      // Calculate totals and averages
      const totalDays = filteredData.length;
      const revenue = filteredData.reduce(
        (acc, curr) => acc + parseFloat(curr.zRead), // Use zRead for revenue
        0
      );
      setTotalRevenue(revenue);
      setAverageDailyRevenue(revenue / totalDays);

      const drinks = filteredData.reduce(
        (acc, curr) => acc + parseInt(curr.drinksCount),
        0
      );
      setAverageDailyDrinks(drinks / totalDays);

      const pizzas = filteredData.reduce(
        (acc, curr) => acc + parseInt(curr.pizzasCount),
        0
      );
      setAverageDailyPizzas(pizzas / totalDays);

      const starters = filteredData.reduce(
        (acc, curr) => acc + parseInt(curr.startersCount),
        0
      );
      setAverageDailyStarters(starters / totalDays);

      const desserts = filteredData.reduce(
        (acc, curr) => acc + parseInt(curr.dessertsCount),
        0
      );
      setAverageDailyDesserts(desserts / totalDays);

      const itemsSold = drinks + pizzas + starters + desserts;
      setTotalItemsSold(itemsSold);

      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      const monthlyRevenue = filteredData
        .filter((d) => {
          const date = new Date(d.shiftDate);
          return (
            date.getMonth() + 1 === currentMonth &&
            date.getFullYear() === currentYear
          );
        })
        .reduce(
          (acc, curr) => acc + parseFloat(curr.zRead), // Use zRead for monthly revenue
          0
        );
      setCumulativeMonthlyRevenue(monthlyRevenue);

      if (filteredData.length > 1) {
        const previousPeriodRevenue = parseFloat(
          filteredData[filteredData.length - 2].zRead // Use zRead for trend calculation
        );
        const previousPeriodItemsSold =
          parseInt(filteredData[filteredData.length - 2].drinksCount) +
          parseInt(filteredData[filteredData.length - 2].pizzasCount) +
          parseInt(filteredData[filteredData.length - 2].startersCount) +
          parseInt(filteredData[filteredData.length - 2].dessertsCount);

        setRevenueTrend(
          ((revenue - previousPeriodRevenue) / previousPeriodRevenue) * 100
        );
        setItemsSoldTrend(
          ((itemsSold - previousPeriodItemsSold) / previousPeriodItemsSold) *
            100
        );
      }
    };

    fetchData();
  }, [dateRange]); // Add `dateRange` as a dependency

  const getLineChartData = (data: EODData[]) => {
    return data
      .map((item) => ({
        shiftDate: item.shiftDate,
        drinksCount: parseInt(item.drinksCount),
        pizzasCount: parseInt(item.pizzasCount),
        startersCount: parseInt(item.startersCount),
        dessertsCount: parseInt(item.dessertsCount),
      }))
      .sort(
        (a, b) =>
          new Date(a.shiftDate).getTime() - new Date(b.shiftDate).getTime()
      );
  };

  return (
    <main className="flex flex-col items-center p-4 min-h-screen bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Overview</h1>
      <div className="w-full max-w-6xl bg-white p-4 rounded-lg shadow-md">
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <DateRange
            editableDateInputs={true}
            onChange={(item) =>
              setDateRange([
                {
                  startDate: item.selection?.startDate ?? new Date(),
                  endDate: item.selection?.endDate ?? new Date(),
                  key: "selection",
                },
              ])
            }
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            showMonthAndYearPickers={true} // Optional: to show month/year pickers
            rangeColors={["#8884d8"]} // Optional: change the color to match your theme
          />
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Total Revenue" />
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Typography variant="h4">
                    €{totalRevenue.toFixed(2)}
                  </Typography>
                  {revenueTrend !== 0 && (
                    <Tooltip title={`Trend: ${revenueTrend.toFixed(2)}%`}>
                      {revenueTrend > 0 ? (
                        <TrendingUpIcon color="success" sx={{ ml: 2 }} />
                      ) : (
                        <TrendingDownIcon color="error" sx={{ ml: 2 }} />
                      )}
                    </Tooltip>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Total Items Sold" />
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Typography variant="h4">{totalItemsSold}</Typography>
                  {itemsSoldTrend !== 0 && (
                    <Tooltip title={`Trend: ${itemsSoldTrend.toFixed(2)}%`}>
                      {itemsSoldTrend > 0 ? (
                        <TrendingUpIcon color="success" sx={{ ml: 2 }} />
                      ) : (
                        <TrendingDownIcon color="error" sx={{ ml: 2 }} />
                      )}
                    </Tooltip>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Cumulative Monthly Revenue" />
              <CardContent>
                <Typography variant="h4">
                  €{cumulativeMonthlyRevenue.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Average Daily Revenue" />
              <CardContent>
                <Typography variant="h4">
                  €{averageDailyRevenue.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Average Daily Drinks Sold" />
              <CardContent>
                <Typography variant="h4">
                  {averageDailyDrinks.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Average Daily Pizzas Sold" />
              <CardContent>
                <Typography variant="h4">
                  {averageDailyPizzas.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Average Daily Starters Sold" />
              <CardContent>
                <Typography variant="h4">
                  {averageDailyStarters.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Average Daily Desserts Sold" />
              <CardContent>
                <Typography variant="h4">
                  {averageDailyDesserts.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Items Sold Over Time" />
              <CardContent>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={getLineChartData(eodData)}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="shiftDate" />
                    <YAxis />
                    <RechartsTooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="drinksCount"
                      stroke="#8884d8"
                    />
                    <Line
                      type="monotone"
                      dataKey="pizzasCount"
                      stroke="#82ca9d"
                    />
                    <Line
                      type="monotone"
                      dataKey="startersCount"
                      stroke="#ffc658"
                    />
                    <Line
                      type="monotone"
                      dataKey="dessertsCount"
                      stroke="#ff7300"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

export default Overview;
