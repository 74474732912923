import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../utils/firebase";

interface Tip {
  id: string;
  shiftDate: string;
  totalTips: number;
  shiftCloser: string;
  individualTips: { name: string; amount: number }[];
}

const TipsDash: React.FC = () => {
  const [tipsData, setTipsData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [people, setPeople] = useState<string[]>([]);

  useEffect(() => {
    const fetchTipsData = async () => {
      try {
        setLoading(true);
        const tipsSnapshot = await getDocs(collection(db, "tips"));
        const tips: Tip[] = [];
        const peopleSet = new Set<string>();

        tipsSnapshot.forEach((doc) => {
          const data = doc.data() as Tip;
          data.individualTips.forEach((tip) => {
            peopleSet.add(tip.name);
          });
          tips.push({
            id: doc.id,
            shiftDate: data.shiftDate,
            totalTips: data.totalTips,
            shiftCloser: data.shiftCloser,
            individualTips: data.individualTips,
          });
        });

        // Sort the tips array by shiftDate
        tips.sort((b, a) => {
          const dateA = new Date(a.shiftDate);
          const dateB = new Date(b.shiftDate);
          return dateA.getTime() - dateB.getTime(); // For ascending order, use dateB.getTime() - dateA.getTime() for descending order
        });

        setPeople(Array.from(peopleSet));
        setTipsData(tips);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tips data: ", error);
        setLoading(false);
      }
    };

    fetchTipsData();
  }, []);

  const columns: GridColDef[] = [
    { field: "shiftDate", headerName: "Date", width: 150 },

    { field: "shiftCloser", headerName: "Shift Closer", width: 150 },
    { field: "totalTips", headerName: "Total Tips", width: 150 },
    ...people.map((person) => ({
      field: person,
      headerName: person,
      width: 100,
    })),
  ];

  const rows = tipsData.map((tip) => {
    const rowData: any = {
      id: tip.id,
      shiftDate: tip.shiftDate,

      shiftCloser: tip.shiftCloser,
      totalTips: tip.totalTips,
    };
    people.forEach((person) => {
      rowData[person] = 0; // Initialize with 0
    });
    tip.individualTips.forEach(
      ({ name, amount }: { name: string; amount: number }) => {
        rowData[name] = amount;
      }
    );
    return rowData;
  });

  return (
    <main className="flex flex-col items-center p-4 min-h-screen bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Tips Dashboard</h1>
      <div className="w-full max-w-6xl bg-white p-4 rounded-lg shadow-md">
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          checkboxSelection
          rows={rows}
          columns={columns}
          autoHeight
          loading={loading}
          getRowId={(row) => row.id}
        />
      </div>
    </main>
  );
};

export default TipsDash;
