/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { collection, getDocs } from "firebase/firestore";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { DateRange } from "react-date-range";
import { db } from "../utils/firebase";

interface Order {
  email: string;
  date: string;
  orders: { [key: string]: Product[] };
}

interface Product {
  product: string;
  size: string;
  supplier: string;
  stockShouldHave: string;
  orderWanted: number | string;
  category?: string;
  hidden?: boolean;
  details?: string;
  costPrice?: number;
}

const OrdersOverview: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [supplierOrders, setSupplierOrders] = useState<any[]>([]);
  const [categoryOrders, setCategoryOrders] = useState<any[]>([]);
  const [topCategory, setTopCategory] = useState<string | null>(null);
  const [totalOrderQuantities, setTotalOrderQuantities] = useState<
    { id: number; product: string; totalQuantity: number }[]
  >([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "product_orders"));
        const ordersData: Order[] = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
        })) as Order[];
        setOrders(ordersData);

        // Process data
        processOrderData(ordersData);
      } catch (error) {
        console.error("Error fetching orders: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    processOrderData(orders);
  }, [dateRange]);

  const processOrderData = (ordersData: Order[]) => {
    const productQuantity: { [key: string]: number } = {};
    const supplierFrequency: { [key: string]: number } = {};
    const categoryFrequency: { [key: string]: number } = {};

    const filteredData = ordersData.filter((order) => {
      const orderDate = new Date(order.date);
      return (
        orderDate >= dateRange[0].startDate && orderDate <= dateRange[0].endDate
      );
    });

    filteredData.forEach((order) => {
      Object.keys(order.orders).forEach((supplier) => {
        if (!supplierFrequency[supplier]) {
          supplierFrequency[supplier] = 0;
        }
        supplierFrequency[supplier] += order.orders[supplier].length;

        order.orders[supplier].forEach((product) => {
          const quantity =
            typeof product.orderWanted === "number"
              ? product.orderWanted
              : parseInt(product.orderWanted);

          if (!productQuantity[product.product]) {
            productQuantity[product.product] = 0;
          }
          productQuantity[product.product] += quantity;

          if (product.category) {
            if (!categoryFrequency[product.category]) {
              categoryFrequency[product.category] = 0;
            }
            categoryFrequency[product.category] += quantity;
          }
        });
      });
    });

    const totalOrderQuantitiesData = Object.keys(productQuantity).map(
      (product, index) => ({
        id: index + 1,
        product,
        totalQuantity: productQuantity[product],
      })
    );

    const supplierOrdersData = Object.keys(supplierFrequency).map(
      (supplier) => ({
        name: supplier,
        orders: supplierFrequency[supplier],
      })
    );

    const categoryOrdersData = Object.keys(categoryFrequency).map(
      (category) => ({
        name: category,
        orders: categoryFrequency[category],
      })
    );

    const topCategoryData =
      categoryOrdersData.length > 0
        ? categoryOrdersData.reduce(
            (prev, current) => (prev.orders > current.orders ? prev : current),
            { name: "", orders: 0 }
          )
        : null;

    setTotalOrderQuantities(totalOrderQuantitiesData);
    setSupplierOrders(supplierOrdersData);
    setCategoryOrders(categoryOrdersData);
    setTopCategory(topCategoryData ? topCategoryData.name : "No orders");
  };

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 90 },
    { field: "product", headerName: "Product", width: 200 },
    { field: "totalQuantity", headerName: "Total Quantity", width: 150 },
  ];

  return (
    <main className="flex-1 w-full max-w-6xl mx-auto p-4">
      <Box p={2}>
        <Typography variant="h4" gutterBottom>
          Orders Overview
        </Typography>

        {/* Date Picker */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <DateRange
            editableDateInputs={true}
            onChange={(item) =>
              setDateRange([
                {
                  startDate: item.selection?.startDate ?? new Date(),
                  endDate: item.selection?.endDate ?? new Date(),
                  key: "selection",
                },
              ])
            }
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            showMonthAndYearPickers={true}
            rangeColors={["#8884d8"]}
          />
        </Box>

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h5">
                      Total Orders by Supplier
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={supplierOrders}
                          dataKey="orders"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {supplierOrders.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h5">
                      Total Quantity by Category
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={categoryOrders}
                          dataKey="orders"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#82ca9d"
                          label
                        >
                          {categoryOrders.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card sx={{ mb: 4 }}>
                  <CardContent>
                    <Typography variant="h5">
                      Supplier with Most Orders
                    </Typography>
                    <Typography variant="h3">
                      {supplierOrders.length > 0
                        ? supplierOrders.reduce((prev, current) =>
                            prev.orders > current.orders ? prev : current
                          ).name
                        : "No orders available"}
                    </Typography>
                  </CardContent>
                </Card>

                <Card>
                  <CardContent>
                    <Typography variant="h5">
                      Category with Most Quantity Ordered
                    </Typography>
                    <Typography variant="h3">{topCategory}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card
                  sx={{
                    mt: 4,
                    p: 2,
                    borderRadius: 2,
                    boxShadow: 3,
                    textAlign: "center",
                  }}
                >
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      Product Order Quantities
                    </Typography>
                    <DataGrid
                      rows={totalOrderQuantities}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 15,
                          },
                        },
                      }}
                      pageSizeOptions={[15, 25, 50, 100]}
                      autoHeight
                      sx={{
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                        "& .MuiDataGrid-cell": {
                          textAlign: "center",
                        },
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </main>
  );
};

export default OrdersOverview;
