import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Button,
  Modal,
  Typography,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import copy from "copy-to-clipboard";
import { db, auth } from "../utils/firebase"; // Import Firebase Firestore and Auth
import { collection, getDocs, addDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

interface Product {
  product: string;
  size: string;
  supplier: string;
  stockShouldHave: string;
  orderWanted: number | string;
  category?: string;
  hidden?: boolean;
  details?: string;
}

const ProductOrders: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [supplierFilter, setSupplierFilter] = useState<string | null>(null);
  const [categoryFilter, setCategoryFilter] = useState<string>("");
  const [productNameFilter, setProductNameFilter] = useState<string>(""); // New state for product name filter
  const [prepareOrderModalOpen, setPrepareOrderModalOpen] =
    useState<boolean>(false);
  const [orderBySupplier, setOrderBySupplier] = useState<{
    [key: string]: Product[];
  }>({});
  const [supplierDescriptions, setSupplierDescriptions] = useState<{
    [key: string]: string;
  }>({});
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [orderTexts, setOrderTexts] = useState<{ [key: string]: string }>({});
  const [orderDetails, setOrderDetails] = useState<{ [key: string]: string }>(
    {}
  ); // New state for order details
  const [userEmail, setUserEmail] = useState<string>("");

  useEffect(() => {
    fetchSuppliersFromFirestore();

    // Listen for auth state changes to get the user's email
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email || "");
      } else {
        // User is signed out, handle accordingly
        setUserEmail("");
      }
    });
  }, []);

  const fetchSuppliersFromFirestore = async () => {
    try {
      const supplierSnapshot = await getDocs(collection(db, "suppliers"));
      const supplierData = supplierSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {} as { [key: string]: any });

      // Gather all item collections in parallel
      const itemPromises = Object.keys(supplierData).map(
        async (supplierName) => {
          const itemsSnapshot = await getDocs(
            collection(db, "suppliers", supplierName, "items")
          );
          const items: Product[] = itemsSnapshot.docs
            .map((itemDoc) => {
              const itemData = itemDoc.data();
              return {
                ...itemData,
                supplier: supplierName,
                orderWanted: itemData.orderWanted || "", // Ensure orderWanted is initialized
              } as Product;
            })
            .filter((item) => !item.hidden); // Filter out hidden items

          return items;
        }
      );

      const itemsResults = await Promise.all(itemPromises);

      // Flatten all items arrays into a single array
      const productsData: Product[] = itemsResults.flat();

      // Prepare supplier descriptions
      const descriptions = Object.keys(supplierData).reduce(
        (acc, supplierName) => {
          if (supplierData[supplierName].description) {
            acc[supplierName] = supplierData[supplierName].description;
          }
          return acc;
        },
        {} as { [key: string]: string }
      );

      // Set the state with the fetched data
      setProducts(productsData);
      setFilteredProducts(productsData);
      setSupplierDescriptions(descriptions);
    } catch (error) {
      console.error("Error fetching products from Firestore: ", error);
    }
  };

  const handleSupplierChange = (event: any, value: string | null) => {
    setSupplierFilter(value);
    filterProducts(value, categoryFilter, productNameFilter);
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setCategoryFilter(value);
    filterProducts(supplierFilter, value, productNameFilter);
  };

  const handleProductNameFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setProductNameFilter(value);
    filterProducts(supplierFilter, categoryFilter, value);
  };

  const filterProducts = (
    supplier: string | null,
    category: string,
    productName: string
  ) => {
    let filtered = products;
    if (supplier) {
      filtered = filtered.filter((product) => product.supplier === supplier);
    }
    if (category) {
      filtered = filtered.filter((product) => product.category === category);
    }
    if (productName) {
      filtered = filtered.filter((product) =>
        product.product?.toLowerCase().includes(productName.toLowerCase())
      );
    }
    setFilteredProducts(filtered);
  };

  const handleOrderWantedChange = (index: number, value: string) => {
    const updatedProducts = [...filteredProducts];
    updatedProducts[index].orderWanted = value;
    setFilteredProducts(updatedProducts);
  };

  const handlePrepareOrder = () => {
    const orders = products.filter((product) => product.orderWanted !== "");
    console.log(orders);

    const groupedOrders = orders.reduce(
      (acc: { [key: string]: Product[] }, product) => {
        if (!acc[product.supplier]) {
          acc[product.supplier] = [];
        }
        acc[product.supplier].push(product);
        return acc;
      },
      {}
    );

    // Set initial order texts for each supplier
    const initialOrderTexts: { [key: string]: string } = {};
    for (const supplier in groupedOrders) {
      initialOrderTexts[supplier] = `${supplier}\n${groupedOrders[supplier]
        .map((product) =>
          product.size === "Unknown"
            ? `${product.product} x ${product.orderWanted}`
            : `${product.product} - ${product.size} x ${product.orderWanted}`
        )
        .join("\n")}`;

      if (orderDetails[supplier]) {
        initialOrderTexts[supplier] += `\nDetails: ${orderDetails[supplier]}`;
      }
    }

    setOrderBySupplier(groupedOrders);
    setOrderTexts(initialOrderTexts);
    setOrderDetails({}); // Reset order details
    setTabIndex(0);
    setPrepareOrderModalOpen(true);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleConfirmOrder = async () => {
    const orderDate = new Date().toISOString();

    const orderData = {
      email: userEmail,
      date: orderDate,
      orders: orderBySupplier,
      orderDetails, // Include order details in the order data
    };
    console.log(orderData);

    try {
      await addDoc(collection(db, "product_orders"), orderData);
      console.log("Order saved to Firebase Firestore.");
      setPrepareOrderModalOpen(false);
    } catch (error) {
      console.error("Error saving order to Firebase Firestore:", error);
    }
  };

  const handleCopyOrder = (supplier: string) => {
    const orderText = orderTexts[supplier];
    copy(orderText, {
      debug: true,
      message: "Press #{key} to copy",
    });
    alert("Order copied to clipboard!");
  };

  const handleOrderTextChange = (supplier: string, value: string) => {
    setOrderTexts({ ...orderTexts, [supplier]: value });
  };

  const handleOrderDetailsChange = (supplier: string, value: string) => {
    setOrderDetails({ ...orderDetails, [supplier]: value });

    // Update the order text live
    setOrderTexts((prevTexts) => ({
      ...prevTexts,
      [supplier]: `${supplier}\n${orderBySupplier[supplier]
        .map((product) =>
          product.size === "Unknown"
            ? `${product.product} x ${product.orderWanted}`
            : `${product.product} - ${product.size} x ${product.orderWanted}`
        )
        .join("\n")}\nDetails: ${value}`,
    }));
  };
  const suppliers = Array.from(
    new Set(products.map((product) => product.supplier))
  );
  const categories = Array.from(
    new Set(products.map((product) => product.category))
  ).filter(Boolean);

  return (
    <main className="flex-1 w-full max-w-4xl mx-auto p-4">
      <Box p={2}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              options={suppliers}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supplier Name"
                  variant="outlined"
                  fullWidth
                />
              )}
              value={supplierFilter}
              onChange={handleSupplierChange}
              clearOnEscape
              onInputChange={(event, value, reason) => {
                if (reason === "clear") {
                  setSupplierFilter(null);
                  filterProducts(null, categoryFilter, productNameFilter);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={categoryFilter}
                onChange={handleCategoryChange}
                label="Category"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Product Name"
              variant="outlined"
              fullWidth
              value={productNameFilter}
              onChange={handleProductNameFilterChange}
            />
          </Grid>
          <Grid item xs={12}>
            {supplierFilter && supplierDescriptions[supplierFilter] && (
              <Typography variant="body1" sx={{ mb: 2 }}>
                {supplierDescriptions[supplierFilter]}
              </Typography>
            )}
            <Button
              variant="contained"
              style={{ color: "black", backgroundColor: "#f78da7" }}
              onClick={handlePrepareOrder}
              fullWidth
            >
              Confirm Order
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
          <Table size="small" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                {" "}
                <TableCell
                  sx={{
                    width: "30%",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  Order wanted
                </TableCell>{" "}
                <TableCell
                  sx={{
                    width: "10%",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  Stock should have
                </TableCell>
                <TableCell
                  sx={{
                    width: "20%",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  Product Name
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  Size
                </TableCell>
                <TableCell
                  sx={{
                    width: "20%",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  Description
                </TableCell>{" "}
                <TableCell
                  sx={{
                    width: "10%",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  Supplier
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProducts.map((product, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      backgroundColor: product.orderWanted
                        ? "#f78da7"
                        : "#EEEEEE",
                    }}
                  >
                    <TextField
                      value={product.orderWanted || ""}
                      onChange={(e) =>
                        handleOrderWantedChange(index, e.target.value)
                      }
                      variant="outlined"
                      type="tel"
                      fullWidth
                    />
                  </TableCell>{" "}
                  <TableCell
                    sx={{ wordWrap: "break-word", whiteSpace: "normal" }}
                  >
                    {product.stockShouldHave}
                  </TableCell>
                  <TableCell
                    sx={{ wordWrap: "break-word", whiteSpace: "normal" }}
                  >
                    {product.product}
                  </TableCell>
                  <TableCell
                    sx={{ wordWrap: "break-word", whiteSpace: "normal" }}
                  >
                    {product.size}
                  </TableCell>
                  <TableCell
                    sx={{ wordWrap: "break-word", whiteSpace: "normal" }}
                  >
                    {product.details}
                  </TableCell>{" "}
                  <TableCell
                    sx={{ wordWrap: "break-word", whiteSpace: "normal" }}
                  >
                    {product.category}
                  </TableCell>
                  <TableCell
                    sx={{ wordWrap: "break-word", whiteSpace: "normal" }}
                  >
                    {product.supplier}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={prepareOrderModalOpen}
          onClose={() => setPrepareOrderModalOpen(false)}
          aria-labelledby="prepare-order-modal-title"
          aria-describedby="prepare-order-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxHeight: "90vh",
              overflowY: "auto",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography
              id="prepare-order-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 2 }}
            >
              Confirm Order
            </Typography>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="supplier tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              {Object.keys(orderBySupplier).map((supplier, index) => (
                <Tab label={supplier} key={index} />
              ))}
            </Tabs>
            {Object.keys(orderBySupplier).map((supplier, index) => (
              <Box
                role="tabpanel"
                hidden={tabIndex !== index}
                key={index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                p={2}
              >
                {tabIndex === index && (
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ mb: 2, fontWeight: "bold" }}
                    >
                      Description:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 2,
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      {supplierDescriptions[supplier] ||
                        "No description available."}
                    </Typography>
                    <TextField
                      label="Additional Details"
                      multiline
                      fullWidth
                      rows={2}
                      value={orderDetails[supplier] || ""}
                      onChange={(e) =>
                        handleOrderDetailsChange(supplier, e.target.value)
                      }
                      variant="outlined"
                      sx={{ mt: 2 }}
                    />
                    <TextField
                      label="Copy Order"
                      multiline
                      fullWidth
                      rows={orderBySupplier[supplier].length + 2} // Adding 2 for the supplier row and details
                      value={orderTexts[supplier]}
                      onChange={(e) =>
                        handleOrderTextChange(supplier, e.target.value)
                      }
                      variant="outlined"
                      sx={{ mt: 2 }}
                    />
                    <Button
                      variant="contained"
                      style={{ color: "black", backgroundColor: "#CA7FAC" }}
                      onClick={() => handleCopyOrder(supplier)}
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      Copy Order to Clipboard
                    </Button>
                  </Box>
                )}
              </Box>
            ))}
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                style={{ color: "black", backgroundColor: "#f78da7" }}
                onClick={handleConfirmOrder}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </main>
  );
};

export default ProductOrders;
