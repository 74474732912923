import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../utils/firebase";
import { useUserRole } from "../contexts/UserRoleContext";

const Employees: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const { role } = useUserRole();
  console.log(role);

  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    role: "",
    category: "",
  });

  const columns: GridColDef[] = [
    { field: "uid", headerName: "ID", width: 150 },
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "category",
      headerName: "Category",
      renderCell: (params) => {
        const capitalizeFirstLetter = (string: string) => {
          return string.charAt(0).toUpperCase() + string.slice(1);
        };

        return params.value ? (
          <span>{capitalizeFirstLetter(params.value)}</span>
        ) : null;
      },
      width: 150,
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      renderCell: (params) => {
        const capitalizeFirstLetter = (string: string) => {
          return string.charAt(0).toUpperCase() + string.slice(1);
        };

        return params.value ? (
          <span>{capitalizeFirstLetter(params.value)}</span>
        ) : null;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleEdit(params.row)}>
            <Edit />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleDelete(params.row.uid)}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = await getDocs(collection(db, "users"));
      const usersList: any[] = [];

      for (const docSnap of usersCollection.docs) {
        const user = docSnap.data();
        usersList.push({ ...user, uid: docSnap.id });
      }

      setUsers(usersList);
    };

    fetchUsers();
  }, []);

  const handleCreateUser = async () => {
    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        newUser.password
      );
      const uid = userCredential.user.uid;

      await setDoc(doc(db, "users", uid), {
        email: newUser.email,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        role: newUser.role,
        category: newUser.category,
      });

      setUsers([
        ...users,
        {
          uid,
          email: newUser.email,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          role: newUser.role,
          category: newUser.category,
        },
      ]);

      setOpen(false);
      resetNewUser();
    } catch (error) {
      console.error("Error creating user: ", error);
    }
  };

  const handleEditUser = async () => {
    if (currentUserId) {
      const updateData: any = {
        email: newUser.email,
        role: newUser.role,
        category: newUser.category || "",
      };

      if (newUser.firstName) {
        updateData.firstName = newUser.firstName;
      }

      if (newUser.lastName) {
        updateData.lastName = newUser.lastName;
      }

      await setDoc(doc(db, "users", currentUserId), updateData, {
        merge: true,
      });

      const updatedUsers = users.map((user) =>
        user.uid === currentUserId
          ? {
              ...user,
              ...updateData,
            }
          : user
      );

      setUsers(updatedUsers);
      setOpen(false);
      resetNewUser();
    }
  };

  const handleDelete = async (uid: string) => {
    await deleteDoc(doc(db, "users", uid));
    setUsers(users.filter((user) => user.uid !== uid));
  };

  const handleEdit = (user: any) => {
    setCurrentUserId(user.uid);
    setNewUser({
      email: user.email || "",
      password: "",
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      role: user.role || "",
      category: user.category || "",
    });
    setIsEditing(true);
    setOpen(true);
  };

  const resetNewUser = () => {
    setNewUser({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      role: "",
      category: "",
    });
    setIsEditing(false);
    setCurrentUserId(null);
  };

  return (
    <main className="flex flex-col items-center flex-1 w-full max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Employees</h1>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={users}
          columns={columns}
          getRowId={(row) => row.uid}
          slots={{
            toolbar: () => (
              <GridToolbarContainer>
                <button
                  className="bg-salumeria hover:bg-salumeria-light text-white font-bold py-2 px-4 rounded"
                  onClick={() => {
                    setOpen(true);
                    resetNewUser();
                  }}
                >
                  Create User
                </button>
                <GridToolbarExport />
              </GridToolbarContainer>
            ),
          }}
        />
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            {isEditing ? "Edit User" : "Create New User"}
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
              disabled={isEditing && newUser.email !== ""}
            />
            {!isEditing && (
              <TextField
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                value={newUser.password}
                onChange={(e) =>
                  setNewUser({ ...newUser, password: e.target.value })
                }
              />
            )}
            <TextField
              margin="dense"
              label="First Name"
              type="text"
              fullWidth
              variant="outlined"
              value={newUser.firstName}
              onChange={(e) =>
                setNewUser({ ...newUser, firstName: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Last Name"
              type="text"
              fullWidth
              variant="outlined"
              value={newUser.lastName}
              onChange={(e) =>
                setNewUser({ ...newUser, lastName: e.target.value })
              }
            />
            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel>Category</InputLabel>
              <Select
                value={newUser.category}
                onChange={(e) =>
                  setNewUser({ ...newUser, category: e.target.value })
                }
                label="Category"
              >
                <MenuItem value="kitchen">Kitchen</MenuItem>
                <MenuItem value="floor">Floor</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel>Role</InputLabel>
              <Select
                value={newUser.role}
                onChange={(e) =>
                  setNewUser({ ...newUser, role: e.target.value })
                }
                label="Role"
              >
                <MenuItem value="employee">Employee</MenuItem>
                <MenuItem value="shiftleader">Shift Leader</MenuItem>
                {(role === "manager" || role === "admin") && (
                  <>
                    <MenuItem value="manager">Manager</MenuItem>
                  </>
                )}
                {role === "admin" && (
                  <>
                    <MenuItem value="admin">Admin</MenuItem>
                  </>
                )}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <button
              className="bg-red-500 hover:bg-red-300 text-white font-bold py-2 px-4 rounded"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button
              onClick={isEditing ? handleEditUser : handleCreateUser}
              className="bg-salumeria hover:bg-salumeria-light text-white font-bold py-2 px-4 rounded"
            >
              {isEditing ? "Save" : "Create"}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </main>
  );
};

export default Employees;
