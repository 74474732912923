import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import SignIn from "./auth/SignIn";
import Navigation from "./components/Navbar";
import Home from "./pages/Home";
import ProductOrders from "./pages/ProductOrders";
import Orders from "./pages/Orders";
import Items from "./pages/Items";
import ForgotPassword from "./auth/ForgotPassword";
import TipsForm from "./pages/Tips";
import TipsDash from "./pages/TipsDash";
import Employees from "./pages/Employees";
import EODForm from "./pages/EOD";
import EODDashboard from "./pages/EODDashboard";
import Overview from "./pages/Overview";
import Roster from "./pages/Roster";
import { useUserRole } from "./contexts/UserRoleContext";
import CalendarPage from "./pages/Calendar";
import OrdersOverview from "./pages/OrdersOverview";

const App: React.FC = () => {
  const { user, role } = useUserRole();

  const isAdmin = role === "admin";
  const isManager = role === "manager";
  const isEmployee = role === "employee";
  const isShiftLeader = role === "shiftleader";

  return (
    <Router>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <Navigation />

        <Routes>
          <Route
            path="/signin"
            element={user ? <Navigate to="/home" /> : <SignIn />}
          />
          <Route
            path="/forgot-password"
            element={user ? <Navigate to="/home" /> : <ForgotPassword />}
          />
          <Route
            path="/orders"
            element={
              user ? (
                isAdmin ? (
                  <Orders />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/ordersoverview"
            element={
              user ? (
                isAdmin ? (
                  <OrdersOverview />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/items"
            element={
              user ? (
                isAdmin ? (
                  <Items />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/calendar"
            element={
              user ? (
                isAdmin ? (
                  <CalendarPage />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/tips-dashboard"
            element={
              user ? (
                isAdmin ? (
                  <TipsDash />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/eoddashboard"
            element={
              user ? (
                isAdmin ? (
                  <EODDashboard />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/overview"
            element={
              user ? (
                isAdmin ? (
                  <Overview />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/employees"
            element={
              user ? (
                isAdmin || isManager ? (
                  <Employees />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/product-orders"
            element={
              user ? (
                isAdmin || isManager || isEmployee || isShiftLeader ? (
                  <ProductOrders />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/tips"
            element={
              user ? (
                isAdmin || isManager || isEmployee || isShiftLeader ? (
                  <TipsForm />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/eod"
            element={
              user ? (
                isAdmin || isManager || isShiftLeader ? (
                  <EODForm />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/roster"
            element={
              user ? (
                isAdmin || isManager || isEmployee || isShiftLeader ? (
                  <Roster />
                ) : (
                  <Navigate to="/home" />
                )
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/home"
            element={user ? <Home /> : <Navigate to="/signin" />}
          />
          <Route
            path="/"
            element={user ? <Navigate to="/home" /> : <Navigate to="/signin" />}
          />
          {/* 404 Fallback route */}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>

        <footer className="bg-salumeria w-full p-4 text-white text-center">
          <a
            href="https://salumeriagardens.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Salumeria Gardens
          </a>{" "}
          &copy; 2024
        </footer>
      </div>
    </Router>
  );
};

export default App;
