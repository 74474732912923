import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { auth } from "../utils/firebase";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Box,
  ButtonBase,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import TipsIcon from "@mui/icons-material/MonetizationOn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import InsightsIcon from "@mui/icons-material/Insights";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useUserRole } from "../contexts/UserRoleContext";
import { CalendarMonth } from "@mui/icons-material";

const Navigation: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width:1665px)");
  const { user, role } = useUserRole();

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const navLinks = [
    {
      to: "/product-orders",
      label: "Purchase Orders",
      icon: <ReceiptIcon />,
      visible:
        role === "admin" ||
        role === "manager" ||
        role === "shiftleader" ||
        role === "employee",
    },
    {
      to: "/orders",
      label: "Purchase Orders Dashboard",
      icon: <ShoppingCartIcon />,
      visible: role === "admin",
    },

    {
      to: "/items",
      label: "Suppliers & Items",
      icon: <InventoryIcon />,
      visible: role === "admin",
    },
    {
      to: "/tips",
      label: "Tips",
      icon: <TipsIcon />,
      visible:
        role === "admin" ||
        role === "manager" ||
        role === "shiftleader" ||
        role === "employee",
    },
    {
      to: "/tips-dashboard",
      label: "Tips Dashboard",
      icon: <DashboardIcon />,
      visible: role === "admin",
    },

    {
      to: "/eod",
      label: "EOD",
      icon: <WbTwilightIcon />,
      visible: role === "admin" || role === "manager" || role === "shiftleader",
    },
    {
      to: "/eoddashboard",
      label: "EOD Dashboard",
      icon: <LineAxisIcon />,
      visible: role === "admin",
    },
    {
      to: "/roster",
      label: "Roster",
      icon: <ScheduleIcon />,
      visible:
        role === "admin" ||
        role === "manager" ||
        role === "shiftleader" ||
        role === "employee",
    },
    {
      to: "/calendar",
      label: "Calendar",
      icon: <CalendarMonth />,
      visible: role === "admin",
    },
    {
      to: "/employees",
      label: "Employees List",
      icon: <PersonIcon />,
      visible: role === "admin" || role === "manager",
    },
    {
      to: "/overview",
      label: "EOD Overview",
      icon: <InsightsIcon />,
      visible: role === "admin",
    },
    {
      to: "/ordersoverview",
      label: "Purchase Orders Overviews",
      icon: <InsightsIcon />,
      visible: role === "admin",
    },
  ];

  const filteredLinks = navLinks.filter((link) => link.visible);

  const drawerItems = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem>
          <ButtonBase component={NavLink} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ButtonBase>
        </ListItem>
        {filteredLinks.map((link) => (
          <ListItem key={link.to}>
            <ButtonBase component={NavLink} to={link.to}>
              <ListItemIcon>{link.icon}</ListItemIcon>
              <ListItemText primary={link.label} />
            </ButtonBase>
          </ListItem>
        ))}
        <Divider />
        <ListItem>
          <ButtonBase
            onClick={() => {
              auth.signOut();
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ButtonBase>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar
          sx={{ justifyContent: "space-between" }}
          className="bg-salumeria"
        >
          {isSmallScreen && user && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <NavLink to="/" className="hover:underline text-white">
              <img
                src="../assets/salumeria.png"
                alt="Salumeria"
                className="h-10"
              />
            </NavLink>
          </Box>

          {user && !isSmallScreen && (
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              {filteredLinks.map((link) => (
                <NavLink
                  key={link.to}
                  to={link.to}
                  className={({ isActive }) =>
                    isActive
                      ? "mr-4 text-red-500"
                      : "mr-4 hover:underline text-white"
                  }
                >
                  {link.label}
                </NavLink>
              ))}
              <button
                onClick={() => auth.signOut()}
                className="bg-red-500 px-4 py-2 rounded hover:bg-red-700 text-white"
              >
                Sign Out
              </button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {isSmallScreen && (
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerItems}
        </Drawer>
      )}
    </>
  );
};

export default Navigation;
