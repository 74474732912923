// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCSkvthTl7D92j7WmSxyEllD0QR567qlt8",
  authDomain: "salumeria-management.firebaseapp.com",
  projectId: "salumeria-management",
  storageBucket: "salumeria-management.appspot.com",
  messagingSenderId: "55668748135",
  appId: "1:55668748135:web:f9a375e04a40acd13509c2",
  measurementId: "G-4L0K8BHBR5",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage, doc, getDoc };
