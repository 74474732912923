import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

interface RosterData {
  [key: string]: {
    [key: string]: {
      [key: string]: Array<{
        startTime: string;
        endTime: string;
        employee: string;
      }>;
    };
  };
}

export const exportPDF = (roster: RosterData, currentWeek: Date[]) => {
  const doc = new jsPDF({ orientation: "landscape" });

  const rolesGroup1 = ["manager", "orders", "runner", "bar", "pass"];
  const rolesGroup2 = ["prep", "kitchen", "cleaner"];

  // Add trial roles but only include them if they have shifts
  const rolesWithTrialsGroup1 = rolesGroup1.concat("trialFloor");
  const rolesWithTrialsGroup2 = rolesGroup2.concat("trialKitchen");

  const generateTable = (shiftType: "Lunch" | "Dinner", roles: string[]) => {
    const headers = [
      "",
      ...currentWeek.map((date) =>
        date.toLocaleDateString("en-GB", {
          weekday: "short",
          day: "numeric",
          month: "short",
        })
      ),
    ];

    const rows: any[] = [];
    let hasShifts = false;

    roles.forEach((role) => {
      const roleName = role.includes("trial")
        ? "Trial"
        : role.charAt(0).toUpperCase() + role.slice(1);
      let hasRoleShifts = false;
      const row = [roleName];

      currentWeek.forEach((date) => {
        const dayString = date.toLocaleDateString("en-GB", {
          weekday: "long",
        });
        const shift = roster[dayString]?.[shiftType]?.[role] || [];

        if (shift.length > 0) {
          hasShifts = true;
          hasRoleShifts = true;
        }

        // Format shifts
        const shiftInfo = shift
          .map((shift: any) =>
            shift.employee
              ? `${shift.employee} (${shift.startTime}-${shift.endTime})`
              : "-"
          )
          .join("\n");

        row.push(shiftInfo || "-");
      });

      // Only add the row if there are shifts for this role during the week
      if (hasRoleShifts) {
        rows.push(row);
      }
    });

    if (hasShifts) {
      autoTable(doc, {
        head: [headers],
        body: rows,
        theme: "striped", // Use striped theme for better readability
        styles: {
          fontSize: 9,
          cellPadding: 6,
        },
        columnStyles: {
          0: {
            cellWidth: 30, // Set width for role column
            fontStyle: "bold", // Make the role names bold
          },
        },
        headStyles: {
          fillColor: [247, 141, 167], // Custom header color
          textColor: [255, 255, 255],
          fontSize: 11,
          fontStyle: "bold",
          minCellHeight: 25,
          halign: "center",
          valign: "middle",
        },
        bodyStyles: {
          minCellHeight: 15, // Increase the minimum cell height for better spacing
        },
        margin: { top: 0, right: 0, bottom: 0, left: 0 }, // Remove padding
        didDrawPage: (data) => {
          // Draw logo on every page
          const imgData = "assets/SalumeriaGardens.png"; // Base64 string of the image
          doc.addImage(imgData, "PNG", 0, 0, 30.5, 24); // Adjust the dimensions and position as needed
        },
      });
    }

    return hasShifts;
  };

  // Generate Lunch tables
  const hasLunchShifts1 = generateTable("Lunch", rolesWithTrialsGroup1);
  let hasLunchShifts2 = false;
  if (hasLunchShifts1) {
    doc.addPage();
    hasLunchShifts2 = generateTable("Lunch", rolesWithTrialsGroup2);
  }

  if (hasLunchShifts1 || hasLunchShifts2) {
    doc.addPage();
  }

  // Generate Dinner tables
  const hasDinnerShifts1 = generateTable("Dinner", rolesWithTrialsGroup1);
  let hasDinnerShifts2 = false;
  if (hasDinnerShifts1) {
    doc.addPage();
    hasDinnerShifts2 = generateTable("Dinner", rolesWithTrialsGroup2);
  }

  // Save the PDF if there are shifts
  if (
    hasDinnerShifts1 ||
    hasDinnerShifts2 ||
    hasLunchShifts1 ||
    hasLunchShifts2
  ) {
    const start = currentWeek[0].toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    doc.save(`roster_starting_${start}.pdf`);
  }
};
