import React, { useState, useEffect } from "react";
import { db } from "../utils/firebase"; // Adjust the import path as necessary
import { collection, getDocs } from "firebase/firestore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
interface EODData {
  id: string;
  shiftDate: string;
  timestamp: any;
  shiftCloser: string;
  startingFloat: string;
  totalCash: string;
  totalCards: string;
  cardReadingsPhotoURL?: string;
  totalReceipts?: string;
  receiptsPhotoURL?: string;
  cancellationsAmount?: string;
  cancellationsPhotoURL?: string;
  cancellationsReason?: string;
  zRead: string;
  discrepancy: string;
  discrepancyReason: string;
  endingFloat: string;
  totalDrinks: string;
  totalCashDrinks: string;
  xItemizedPhotoURL?: string;
  zReadingPhotoURL?: string;
  shiftNotes?: string;
  invoicingEventAmount?: string;
  nonPhysicalBillPhotoURLs?: string[];
  moneyLent?: string;
  moneyLentReason?: string;
  drinksCount: string;
  drinksValue: string;
  pizzasCount: string;
  pizzasValue: string;
  startersCount: string;
  startersValue: string;
  dessertsCount: string;
  dessertsValue: string;
  userEmail: string;
}

const EODDashboard: React.FC = () => {
  const [eodData, setEodData] = useState<EODData[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "eod_reports"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as EODData[];

      // Sort data by timestamp
      data.sort((b, a) => {
        const dateA = a.timestamp ? a.timestamp.toDate() : 0;
        const dateB = b.timestamp ? b.timestamp.toDate() : 0;
        return dateA - dateB; // For ascending order, use dateB - dateA for descending order
      });

      setEodData(data);
    };

    fetchData();
  }, []);

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: "Time Stamp",
      width: 150,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) =>
        params.value
          ? dayjs(params.value.toDate()).format("HH:mm:ss DD/MM/YYYY")
          : "",
    },
    { field: "shiftCloser", headerName: "Shift Closer", width: 150 },
    { field: "zRead", headerName: "Z Read", width: 150 },
    { field: "shiftDate", headerName: "Shift Date", width: 150 },
    { field: "userEmail", headerName: "User Email", width: 200 },
    { field: "startingFloat", headerName: "Starting Float", width: 150 },
    { field: "totalCash", headerName: "Total Cash", width: 150 },
    { field: "totalCards", headerName: "Total Cards", width: 150 },
    {
      field: "cardReadingsPhotoURL",
      headerName: "Card Readings Photo",
      width: 200,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) =>
        params.value ? (
          <Button onClick={() => handleImageClick(params.value)}>View</Button>
        ) : null,
    },
    { field: "totalReceipts", headerName: "Total Receipts", width: 150 },
    {
      field: "receiptsPhotoURL",
      headerName: "Receipts Photo",
      width: 200,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) =>
        params.value ? (
          <Button onClick={() => handleImageClick(params.value)}>View</Button>
        ) : null,
    },
    {
      field: "cancellationsAmount",
      headerName: "Cancellations Amount",
      width: 150,
    },
    {
      field: "cancellationsPhotoURL",
      headerName: "Cancellations Photo",
      width: 200,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) =>
        params.value ? (
          <Button onClick={() => handleImageClick(params.value)}>View</Button>
        ) : null,
    },
    {
      field: "cancellationsReason",
      headerName: "Cancellations Reason",
      width: 200,
    },

    { field: "discrepancy", headerName: "Discrepancy", width: 150 },
    {
      field: "discrepancyReason",
      headerName: "Discrepancy Reason",
      width: 150,
    },
    { field: "endingFloat", headerName: "Ending Float", width: 150 },
    { field: "totalDrinks", headerName: "Total Drinks", width: 150 },
    { field: "totalCashDrinks", headerName: "Total Cash Drinks", width: 150 },
    {
      field: "xItemizedPhotoURL",
      headerName: "X Itemized Photo",
      width: 200,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) =>
        params.value ? (
          <Button onClick={() => handleImageClick(params.value)}>View</Button>
        ) : null,
    },
    {
      field: "zReadingPhotoURL",
      headerName: "Z Reading Photo",
      width: 200,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) =>
        params.value ? (
          <Button onClick={() => handleImageClick(params.value)}>View</Button>
        ) : null,
    },
    { field: "shiftNotes", headerName: "Shift Notes", width: 200 },
    {
      field: "invoicingEventAmount",
      headerName: "Invoicing Event Amount",
      width: 150,
    },
    {
      field: "nonPhysicalBillPhotoURLs",
      headerName: "Non-Physical Bill Photos",
      width: 200,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) =>
        params.value ? (
          <>
            {params.value.map((url: string, index: number) => (
              <Button key={index} onClick={() => handleImageClick(url)}>
                View {index + 1}
              </Button>
            ))}
          </>
        ) : null,
    },
    { field: "moneyLent", headerName: "Money Lent", width: 150 },
    { field: "moneyLentReason", headerName: "Money Lent Reason", width: 200 },
    { field: "drinksCount", headerName: "Drinks Count", width: 150 },
    { field: "drinksValue", headerName: "Drinks Value", width: 150 },
    { field: "pizzasCount", headerName: "Pizzas Count", width: 150 },
    { field: "pizzasValue", headerName: "Pizzas Value", width: 150 },
    { field: "startersCount", headerName: "Starters Count", width: 150 },
    { field: "startersValue", headerName: "Starters Value", width: 150 },
    { field: "dessertsCount", headerName: "Desserts Count", width: 150 },
    { field: "dessertsValue", headerName: "Desserts Value", width: 150 },
  ];
  console.log(eodData);

  return (
    <main className="flex flex-col items-center p-4 min-h-screen bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">End of Day Dashboard</h1>
      <div className="w-full max-w-6xl bg-white p-4 rounded-lg shadow-md">
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          checkboxSelection
          rows={eodData}
          columns={columns}
        />

        <Dialog open={modalOpen} onClose={handleCloseModal}>
          <DialogTitle>Image Preview</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <img
                src={selectedImage || ""}
                alt="Preview"
                style={{ width: "100%" }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </main>
  );
};

export default EODDashboard;
