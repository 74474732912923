import React, { useEffect, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { auth } from "../utils/firebase";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignIn: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 9000);

    return () => clearTimeout(timer);
  }, []);

  const getErrorMessage = (errorCode: string) => {
    console.log(errorCode);

    switch (errorCode) {
      case "auth/invalid-email":
        return "Invalid email address.";
      case "auth/user-disabled":
        return "This user has been disabled.";
      case "auth/user-not-found":
        return "No user found with this email.";
      case "auth/wrong-password":
        return "Incorrect password.";
      case "auth/invalid-credential":
        return "Incorrect email address or password.";
      case "auth/too-many-requests":
        return "Too many requests. Please try again later.";
      default:
        return "An unknown error occurred. Please try again.";
    }
  };

  const signIn = () => {
    signInWithEmailAndPassword(auth, email, password).catch((error) =>
      Swal.fire("Error", getErrorMessage(error.code), "error")
    );
  };

  return (
    <div className="relative flex justify-center items-center h-screen bg-gray-100">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover opacity-50"
      >
        <source src="assets/salumeria2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div
        className={`relative p-4 bg-white rounded-lg shadow-md max-w-md w-full z-10 transition-transform duration-500 ease-in-out ${
          showModal ? "transform scale-100" : "transform scale-0"
        }`}
      >
        <h2 className="text-2xl mb-4">Sign In</h2>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="border p-2 mb-4 w-full"
        />
        <div className="relative mb-4">
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="border p-2 w-full"
          />
          <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>
        <button
          onClick={signIn}
          className="bg-blue-500 text-white p-2 w-full mb-2"
        >
          Sign In
        </button>
        <div className="flex justify-between mt-4">
          <Link to="/forgot-password" className="text-blue-500">
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
