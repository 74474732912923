import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer, Event, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop, {
  withDragAndDropProps,
} from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

// Setup the localizer by providing the moment (or globalize) Object
const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

// Malta public holidays (example, you might need to update this with real data)
const maltaPublicHolidays = [
  { title: "New Year's Day", date: "2024-01-01" },
  { title: "Feast of St Paul's Shipwreck", date: "2024-02-10" },
  { title: "Feast of St Joseph", date: "2024-03-19" },
  { title: "Good Friday", date: "2024-03-29" },
  { title: "Freedom Day", date: "2024-03-31" },
  { title: "Workers' Day", date: "2024-05-01" },
  { title: "Sette Giugno", date: "2024-06-07" },
  { title: "Feast of St Peter and St Paul", date: "2024-06-29" },
  { title: "Feast of the Assumption", date: "2024-08-15" },
  { title: "Victory Day", date: "2024-09-08" },
  { title: "Independence Day", date: "2024-09-21" },
  { title: "Immaculate Conception", date: "2024-12-08" },
  { title: "Republic Day", date: "2024-12-13" },
  { title: "Christmas Day", date: "2024-12-25" },
];

interface CustomEvent extends Event {
  id?: string;
}

const CalendarPage: React.FC = () => {
  const [events, setEvents] = useState<CustomEvent[]>([]);
  const [open, setOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState<CustomEvent | null>(null);
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
  });

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    const db = getFirestore();
    const eventsCollection = await getDocs(collection(db, "events"));
    const eventsList = eventsCollection.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      start: doc.data().start.toDate(),
      end: doc.data().end.toDate(),
    })) as CustomEvent[];
    console.log(eventsList);

    const holidays = maltaPublicHolidays.map((holiday) => ({
      title: holiday.title,
      start: new Date(holiday.date),
      end: new Date(holiday.date),
      allDay: true,
    }));

    setEvents([...eventsList, ...holidays]);
  };

  const handleSelectSlot = ({ start, end }: { start: Date; end: Date }) => {
    setEditingEvent(null);
    setNewEvent({ title: "", start, end });
    setOpen(true);
  };

  const handleSelectEvent = (event: CustomEvent) => {
    setEditingEvent(event);
    setNewEvent({
      title: String(event.title),
      start: event.start || new Date(),
      end: event.end || new Date(),
    });
    setOpen(true);
  };

  const handleSaveEvent = async () => {
    const db = getFirestore();
    if (editingEvent) {
      const eventDoc = doc(db, "events", editingEvent.id as string);
      await updateDoc(eventDoc, newEvent);
      setEvents(
        events.map((event) =>
          event.id === editingEvent.id ? { ...newEvent, id: event.id } : event
        )
      );
    } else {
      const docRef = await addDoc(collection(db, "events"), newEvent);
      setEvents([...events, { ...newEvent, id: docRef.id }]);
    }
    setOpen(false);
  };

  const handleDeleteEvent = async () => {
    if (editingEvent) {
      const db = getFirestore();
      const eventDoc = doc(db, "events", editingEvent.id as string);
      await deleteDoc(eventDoc);
      setEvents(events.filter((event) => event.id !== editingEvent.id));
      setOpen(false);
    }
  };

  const moveEvent: withDragAndDropProps["onEventDrop"] = async ({
    event,
    start,
    end,
  }) => {
    const updatedEvent = {
      ...event,
      start: new Date(start),
      end: new Date(end),
    };
    const db = getFirestore();
    const eventDoc = doc(db, "events", (event as CustomEvent).id as string); // Add type assertion to CustomEvent
    await updateDoc(eventDoc, updatedEvent);
    setEvents(
      events.map((evt) =>
        evt.id === (event as CustomEvent).id ? updatedEvent : evt
      )
    ); // Add type assertion to CustomEvent
  };

  const resizeEvent: withDragAndDropProps["onEventResize"] = async ({
    event,
    start,
    end,
  }) => {
    const updatedEvent = {
      ...event,
      start: new Date(start),
      end: new Date(end),
    };
    const db = getFirestore();
    const eventDoc = doc(db, "events", (event as CustomEvent).id as string); // Add type assertion to CustomEvent
    await updateDoc(eventDoc, updatedEvent);
    setEvents(
      events.map((evt) =>
        evt.id === (event as CustomEvent).id ? updatedEvent : evt
      )
    ); // Add type assertion to CustomEvent
  };

  return (
    <main className="flex flex-col flex-1 w-full max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 mx-auto block">Calendar</h1>
      <DragAndDropCalendar
        localizer={localizer}
        events={events}
        startAccessor={(event: CustomEvent) => new Date(event.start || "")}
        endAccessor={(event: CustomEvent) => new Date(event.end || "")}
        style={{ height: 500 }}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        onEventDrop={moveEvent}
        resizable
        onEventResize={resizeEvent}
        views={[Views.MONTH, Views.WEEK, Views.DAY]}
      />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {editingEvent ? "Edit Event" : "Add New Event"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Event Title"
            fullWidth
            value={newEvent.title}
            onChange={(e) =>
              setNewEvent({ ...newEvent, title: e.target.value })
            }
            margin="normal"
          />
          <TextField
            label="Start Date"
            type="datetime-local"
            fullWidth
            value={moment(newEvent.start).format("YYYY-MM-DDTHH:mm")}
            onChange={(e) =>
              setNewEvent({ ...newEvent, start: new Date(e.target.value) })
            }
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            type="datetime-local"
            fullWidth
            value={moment(newEvent.end).format("YYYY-MM-DDTHH:mm")}
            onChange={(e) =>
              setNewEvent({ ...newEvent, end: new Date(e.target.value) })
            }
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          {editingEvent && (
            <Button onClick={handleDeleteEvent} color="error">
              Delete
            </Button>
          )}
          <Button onClick={handleSaveEvent} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default CalendarPage;
