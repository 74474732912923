import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

interface UserRoleContextType {
  user: User | null;
  role: string | null;
  username: string | null;
  firstName: string | null;
}

const UserRoleContext = createContext<UserRoleContextType>({
  user: null,
  role: null,
  username: null,
  firstName: null,
});

export const UserRoleProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [role, setRole] = useState<string | null>(() => {
    return localStorage.getItem("role");
  });
  const [username, setUsername] = useState<string | null>(() => {
    return localStorage.getItem("username");
  });
  const [firstName, setFirstName] = useState<string | null>(() => {
    return localStorage.getItem("firstName");
  });

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {
      if (user) {
        setUser(user);
        setUsername(user.email);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("username", user.email!);

        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const role = userDoc.data()?.role || null;
            const firstName = userDoc.data()?.firstName || null;
            setRole(role);
            setFirstName(firstName);
            localStorage.setItem("role", role);
            localStorage.setItem("firstName", firstName);
          } else {
            setRole(null);
            setFirstName(null);
            localStorage.removeItem("role");
            localStorage.removeItem("firstName");
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
          setRole(null);
          setFirstName(null);
          localStorage.removeItem("role");
          localStorage.removeItem("firstName");
        }
      } else {
        setUser(null);
        setUsername(null);
        setRole(null);
        setFirstName(null);
        localStorage.removeItem("user");
        localStorage.removeItem("username");
        localStorage.removeItem("role");
        localStorage.removeItem("firstName");
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <UserRoleContext.Provider value={{ user, role, username, firstName }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => {
  return useContext(UserRoleContext);
};
