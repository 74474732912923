import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../utils/firebase"; // Import Firebase Firestore

interface Order {
  email: string;
  date: string;
  orders: { [key: string]: Product[] };
}

interface Product {
  product: string;
  size: string;
  supplier: string;
  stockShouldHave: string;
  orderWanted: number | string;
  category?: string;
  hidden?: boolean;
  details?: string;
  costPrice?: number;
}

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "product_orders"));
        const ordersData: Order[] = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
        })) as Order[];
        setOrders(ordersData);
      } catch (error) {
        console.error("Error fetching orders: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);
  console.log(orders);

  const allOrderItems = orders.flatMap((order) =>
    Object.keys(order.orders).flatMap((supplier) =>
      order.orders[supplier].map((product, index) => ({
        id: `${order.email}-${order.date}-${supplier}-${index}`,
        date: new Date(order.date).toLocaleString(),
        email: order.email,
        supplier,
        product: product.product,
        size: product.size,
        orderWanted: product.orderWanted,
        costPrice: product.costPrice,
        description: product.details,
        category: product.category,
        stockShouldHave: product.stockShouldHave,
      }))
    )
  );

  const columns: GridColDef[] = [
    { field: "date", headerName: "Date", width: 180 },
    { field: "email", headerName: "User", width: 200 },
    { field: "supplier", headerName: "Supplier", width: 200 },
    { field: "category", headerName: "Category", width: 150 },
    { field: "product", headerName: "Item", width: 200 },
    { field: "size", headerName: "Size", width: 150 },

    { field: "description", headerName: "Details", width: 150 },
    { field: "stockShouldHave", headerName: "Stock Should Have", width: 150 },
    {
      field: "orderWanted",
      headerName: "Quantity",
      width: 150,
    },
    {
      field: "costPrice",
      headerName: "Cost Price",
      width: 150,
    },
  ];

  return (
    <main className="flex-1 w-full max-w-4xl mx-auto p-4">
      <Box p={2}>
        <Typography variant="h4" gutterBottom>
          All Orders
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                slots={{ toolbar: GridToolbar }}
                rows={allOrderItems}
                columns={columns}
                checkboxSelection
              />
            </div>
          </Box>
        )}
      </Box>
    </main>
  );
};

export default Orders;
