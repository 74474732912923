import React, { useState, useMemo } from "react";
import {
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import {
  getFirestore,
  setDoc,
  doc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { format, differenceInMinutes, parse } from "date-fns";

interface RosterEditModalProps {
  open: boolean;
  onClose: () => void;
  roster: any;
  setRoster: (roster: any) => void;
  employees: any[];
  currentWeek: Date[];
}

const RosterEditModal: React.FC<RosterEditModalProps> = ({
  open,
  onClose,
  roster,
  setRoster,
  employees,
  currentWeek,
}) => {
  const db = getFirestore();
  const [activeTab, setActiveTab] = useState("Dinner");

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTab(newValue);
  };

  // Function to calculate the duration in minutes
  const calculateDuration = (startTime: string, endTime: string) => {
    const start = parse(startTime, "HH:mm", new Date());
    const end = parse(endTime, "HH:mm", new Date());
    return differenceInMinutes(end, start);
  };

  // Function to calculate total hours for a specific employee
  const calculateTotalHoursForEmployee = useMemo(
    () => (employeeName: string) => {
      let totalMinutes = 0;

      Object.keys(roster).forEach((day) => {
        const shiftsForDay = roster[day];
        Object.keys(shiftsForDay).forEach((shiftType) => {
          const roles = shiftsForDay[shiftType];
          Object.keys(roles).forEach((role) => {
            const shifts = roles[role];
            if (Array.isArray(shifts)) {
              shifts.forEach((shift: any) => {
                if (shift.employee === employeeName) {
                  totalMinutes += shift.duration || 0;
                }
              });
            }
          });
        });
      });

      return (totalMinutes / 60).toFixed(2); // Convert minutes to hours and format to 2 decimal places
    },
    [roster]
  );

  const handleNewShiftChange = (
    role: string,
    date: string,
    shiftType: string,
    index: number,
    field: string,
    value: any,
    dayName: string
  ) => {
    const updatedRoster = { ...roster };
    if (!updatedRoster[date]) updatedRoster[date] = {};
    if (!updatedRoster[date][shiftType]) updatedRoster[date][shiftType] = {};
    if (!updatedRoster[date][shiftType][role])
      updatedRoster[date][shiftType][role] = [];

    const shift = updatedRoster[dayName][shiftType][role][index];

    if (field === "employee" && !value) {
      shift.startTime = shiftType === "Dinner" ? "16:00" : "11:00";
      shift.endTime = shiftType === "Dinner" ? "23:00" : "16:00";
    } else if (field === "startTime" && !value) {
      shift.startTime = shiftType === "Dinner" ? "16:00" : "11:00";
    } else if (field === "endTime" && !value) {
      shift.endTime = shiftType === "Dinner" ? "23:00" : "16:00";
    } else {
      shift[field] = value;
    }

    // Calculate and set the duration in minutes
    if (shift.startTime && shift.endTime) {
      shift.duration = calculateDuration(shift.startTime, shift.endTime);
    }

    setRoster(updatedRoster);
  };

  const addNewShiftRoleInstance = (
    role: string,
    date: string,
    shiftType: string,
    dayString: string
  ) => {
    const updatedRoster = { ...roster };

    // Ensure the nested objects are initialized
    if (!updatedRoster[dayString]) updatedRoster[dayString] = {};
    if (!updatedRoster[dayString][shiftType])
      updatedRoster[dayString][shiftType] = {};
    if (!updatedRoster[dayString][shiftType][role])
      updatedRoster[dayString][shiftType][role] = [];

    updatedRoster[dayString][shiftType][role].push({
      employee: "",
      startTime: shiftType === "Dinner" ? "16:00" : "11:00",
      endTime: shiftType === "Dinner" ? "23:00" : "16:00",
      duration: 420, // Default duration in minutes (7 hours)
    });

    setRoster(updatedRoster);
  };

  const removeShiftRoleInstance = (
    role: string,
    date: string,
    shiftType: string,
    index: number
  ) => {
    const updatedRoster = { ...roster };
    if (
      updatedRoster[date] &&
      updatedRoster[date][shiftType] &&
      updatedRoster[date][shiftType][role]
    ) {
      updatedRoster[date][shiftType][role].splice(index, 1);
      setRoster(updatedRoster);
    }
  };

  const saveRoster = async () => {
    try {
      for (const dateObj of currentWeek) {
        const formattedDate = format(dateObj, "yyyy-MM-dd");
        const dayName = format(dateObj, "EEEE");
        const shiftsForDay = roster[dayName]; // Access using the day name

        if (shiftsForDay) {
          for (const shiftType in shiftsForDay) {
            const shiftData = {
              ...shiftsForDay[shiftType],
              date: formattedDate, // Save the date in each shift
              shiftType,
            };

            const shiftsCollection = collection(db, "shifts");
            const q = query(
              shiftsCollection,
              where("date", "==", formattedDate),
              where("shiftType", "==", shiftType)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
              querySnapshot.forEach(async (docSnapshot) => {
                await setDoc(doc(db, "shifts", docSnapshot.id), shiftData, {
                  merge: true,
                });
              });
            } else {
              await addDoc(collection(db, "shifts"), shiftData);
            }
          }
        }
      }
      onClose();
    } catch (error) {
      console.error("Error saving roster: ", error);
    }
  };

  const getFilteredEmployees = (role: string) => {
    if (["prep", "kitchen", "cleaner", "trialKitchen"].includes(role)) {
      return employees.filter((emp) => emp.category === "kitchen");
    } else {
      return employees.filter((emp) => emp.category === "floor");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Roster</DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Dinner" value="Dinner" />
          <Tab label="Lunch" value="Lunch" />
        </Tabs>

        {/* Floor Section */}
        <Box border={1} borderRadius={4} borderColor="grey.300" mb={4} p={2}>
          <Typography variant="h6" gutterBottom>
            Floor
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: "100px" }}>Role</TableCell>
                  {currentWeek.map((date) => {
                    const dayName = format(date, "EEEE"); // Get the day name
                    return (
                      <TableCell
                        key={`${dayName}-${activeTab}`}
                        style={{ minWidth: "300px" }}
                      >
                        {dayName} {activeTab}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {[
                  "manager",
                  "orders",
                  "runner",
                  "bar",
                  "pass",
                  "trialFloor",
                ].map((role) => (
                  <TableRow key={role}>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {role === "trialFloor"
                        ? "Trial"
                        : role.charAt(0).toUpperCase() + role.slice(1)}
                    </TableCell>
                    {currentWeek.map((date) => {
                      const formattedDate = format(date, "yyyy-MM-dd");
                      const dayName = format(date, "EEEE");

                      return (
                        <TableCell
                          key={`${role}-${dayName}-${activeTab}`}
                          style={{
                            verticalAlign: "top",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {(roster[dayName]?.[activeTab]?.[role] || []).map(
                            (instance: any, index: number) => {
                              const startTime = instance.startTime || "";
                              const endTime = instance.endTime || "";

                              return (
                                <Grid
                                  container
                                  spacing={1}
                                  key={index}
                                  sx={{ mb: 2 }}
                                  alignItems="center"
                                >
                                  <Grid item xs={8}>
                                    {role === "trialFloor" ||
                                    role === "trialKitchen" ? (
                                      <TextField
                                        label="Trial Employee Name"
                                        fullWidth
                                        value={instance.employee || ""}
                                        onChange={(event) =>
                                          handleNewShiftChange(
                                            role,
                                            formattedDate,
                                            activeTab,
                                            index,
                                            "employee",
                                            event.target.value,
                                            dayName
                                          )
                                        }
                                        margin="normal"
                                      />
                                    ) : (
                                      <Select
                                        value={instance.employee || ""}
                                        onChange={(event) =>
                                          handleNewShiftChange(
                                            role,
                                            formattedDate,
                                            activeTab,
                                            index,
                                            "employee",
                                            event.target.value,
                                            dayName
                                          )
                                        }
                                        displayEmpty
                                        variant="outlined"
                                      >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        {getFilteredEmployees(role).map(
                                          (emp) => {
                                            const totalHours =
                                              calculateTotalHoursForEmployee(
                                                emp.firstName
                                              );
                                            return (
                                              <MenuItem
                                                key={emp.firstName}
                                                value={emp.firstName}
                                              >
                                                {emp.firstName} ({totalHours}{" "}
                                                hours)
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </Select>
                                    )}
                                  </Grid>

                                  <Grid item xs={2}>
                                    <IconButton
                                      onClick={() =>
                                        removeShiftRoleInstance(
                                          role,
                                          formattedDate,
                                          activeTab,
                                          index
                                        )
                                      }
                                      size="small"
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Grid>
                                  {index ===
                                    roster[dayName][activeTab][role].length -
                                      1 && (
                                    <Grid item xs={2}>
                                      <IconButton
                                        onClick={() =>
                                          addNewShiftRoleInstance(
                                            role,
                                            formattedDate,
                                            activeTab,
                                            dayName
                                          )
                                        }
                                        size="small"
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </Grid>
                                  )}
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Start"
                                      type="time"
                                      fullWidth
                                      value={startTime}
                                      onChange={(e) =>
                                        handleNewShiftChange(
                                          role,
                                          formattedDate,
                                          activeTab,
                                          index,
                                          "startTime",
                                          e.target.value,
                                          dayName
                                        )
                                      }
                                      margin="normal"
                                      inputProps={{
                                        step: 900, // 15 minutes
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="End"
                                      type="time"
                                      fullWidth
                                      value={endTime}
                                      onChange={(e) =>
                                        handleNewShiftChange(
                                          role,
                                          formattedDate,
                                          activeTab,
                                          index,
                                          "endTime",
                                          e.target.value,
                                          dayName
                                        )
                                      }
                                      margin="normal"
                                      inputProps={{
                                        step: 900, // 15 minutes
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}

                          {(!roster[dayName]?.[activeTab]?.[role] ||
                            roster[dayName][activeTab][role].length === 0) && (
                            <Grid container spacing={1} alignItems="center">
                              <Grid item xs={8}>
                                <Button
                                  onClick={() =>
                                    addNewShiftRoleInstance(
                                      role,
                                      formattedDate,
                                      activeTab,
                                      dayName
                                    )
                                  }
                                  startIcon={<AddIcon />}
                                >
                                  Add Employee
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Kitchen Section */}
        <Box border={1} borderRadius={4} borderColor="grey.300" mb={4} p={2}>
          <Typography variant="h6" gutterBottom>
            Kitchen
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: "100px" }}>Role</TableCell>
                  {currentWeek.map((date) => {
                    const dayName = format(date, "EEEE"); // Get the day name
                    return (
                      <TableCell
                        key={`${dayName}-${activeTab}`}
                        style={{ minWidth: "300px" }}
                      >
                        {dayName} {activeTab}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {["prep", "kitchen", "cleaner", "trialKitchen"].map((role) => (
                  <TableRow key={role}>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {role === "trialKitchen"
                        ? "Trial"
                        : role.charAt(0).toUpperCase() + role.slice(1)}
                    </TableCell>
                    {currentWeek.map((date) => {
                      const formattedDate = format(date, "yyyy-MM-dd");
                      const dayName = format(date, "EEEE");

                      return (
                        <TableCell
                          key={`${role}-${dayName}-${activeTab}`}
                          style={{
                            verticalAlign: "top",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {(roster[dayName]?.[activeTab]?.[role] || []).map(
                            (instance: any, index: number) => {
                              const startTime = instance.startTime || "";
                              const endTime = instance.endTime || "";

                              return (
                                <Grid
                                  container
                                  spacing={1}
                                  key={index}
                                  sx={{ mb: 2 }}
                                  alignItems="center"
                                >
                                  <Grid item xs={8}>
                                    {role === "trialFloor" ||
                                    role === "trialKitchen" ? (
                                      <TextField
                                        label="Trial Employee Name"
                                        fullWidth
                                        value={instance.employee || ""}
                                        onChange={(event) =>
                                          handleNewShiftChange(
                                            role,
                                            formattedDate,
                                            activeTab,
                                            index,
                                            "employee",
                                            event.target.value,
                                            dayName
                                          )
                                        }
                                        margin="normal"
                                      />
                                    ) : (
                                      <Select
                                        value={instance.employee || ""}
                                        onChange={(event) =>
                                          handleNewShiftChange(
                                            role,
                                            formattedDate,
                                            activeTab,
                                            index,
                                            "employee",
                                            event.target.value,
                                            dayName
                                          )
                                        }
                                        displayEmpty
                                        variant="outlined"
                                      >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        {getFilteredEmployees(role).map(
                                          (emp) => {
                                            const totalHours =
                                              calculateTotalHoursForEmployee(
                                                emp.firstName
                                              );
                                            return (
                                              <MenuItem
                                                key={emp.firstName}
                                                value={emp.firstName}
                                              >
                                                {emp.firstName} ({totalHours}{" "}
                                                hours)
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                      </Select>
                                    )}
                                  </Grid>

                                  <Grid item xs={2}>
                                    <IconButton
                                      onClick={() =>
                                        removeShiftRoleInstance(
                                          role,
                                          formattedDate,
                                          activeTab,
                                          index
                                        )
                                      }
                                      size="small"
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Grid>
                                  {index ===
                                    roster[dayName][activeTab][role].length -
                                      1 && (
                                    <Grid item xs={2}>
                                      <IconButton
                                        onClick={() =>
                                          addNewShiftRoleInstance(
                                            role,
                                            formattedDate,
                                            activeTab,
                                            dayName
                                          )
                                        }
                                        size="small"
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </Grid>
                                  )}
                                  <Grid item xs={6}>
                                    <TextField
                                      label="Start"
                                      type="time"
                                      fullWidth
                                      value={startTime}
                                      onChange={(e) =>
                                        handleNewShiftChange(
                                          role,
                                          formattedDate,
                                          activeTab,
                                          index,
                                          "startTime",
                                          e.target.value,
                                          dayName
                                        )
                                      }
                                      margin="normal"
                                      inputProps={{
                                        step: 900, // 15 minutes
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      label="End"
                                      type="time"
                                      fullWidth
                                      value={endTime}
                                      onChange={(e) =>
                                        handleNewShiftChange(
                                          role,
                                          formattedDate,
                                          activeTab,
                                          index,
                                          "endTime",
                                          e.target.value,
                                          dayName
                                        )
                                      }
                                      margin="normal"
                                      inputProps={{
                                        step: 900, // 15 minutes
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            }
                          )}

                          {(!roster[dayName]?.[activeTab]?.[role] ||
                            roster[dayName][activeTab][role].length === 0) && (
                            <Grid container spacing={1} alignItems="center">
                              <Grid item xs={8}>
                                <Button
                                  onClick={() =>
                                    addNewShiftRoleInstance(
                                      role,
                                      formattedDate,
                                      activeTab,
                                      dayName
                                    )
                                  }
                                  startIcon={<AddIcon />}
                                >
                                  Add Employee
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={saveRoster} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RosterEditModal;
