import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { startOfWeek, endOfWeek, addDays, format } from "date-fns";
import { useUserRole } from "../contexts/UserRoleContext";
import { exportPDF } from "../utils/pdfUtils";
import updateLocale from "dayjs/plugin/updateLocale";
import WeekPicker from "../components/WeekPicker";
import RosterEditModal from "../modals/RosterEditModal";
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

const Roster: React.FC = () => {
  const [roster, setRoster] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false); // State for edit modal

  const [newShift, setNewShift] = useState<any>({
    date: "",
    shiftType: "Dinner",
    manager: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
    orders: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
    runner: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
    bar: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
    pass: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
    prep: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
    kitchen: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
    cleaner: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
    trialFloor: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
    trialKitchen: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
  });
  const [employees, setEmployees] = useState<any[]>([]);
  const [currentWeek, setCurrentWeek] = useState<Date[]>(getCurrentWeek(null));
  let [startDate] = useState<Dayjs | null>(dayjs().startOf("week"));
  const [filterDate, setFilterDate] = useState<Dayjs | null>(
    dayjs().startOf("week")
  );
  const [editingShift, setEditingShift] = useState<any>(null);
  const [showOnlyMyShifts, setShowOnlyMyShifts] = useState(false);
  const { role, firstName } = useUserRole();

  useEffect(() => {
    fetchEmployees();
    fetchRoster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate, showOnlyMyShifts]);

  function getCurrentWeek(filterDate: Date | null) {
    const now = filterDate || new Date();
    const start = startOfWeek(now, { weekStartsOn: 1 });
    const end = endOfWeek(now, { weekStartsOn: 1 });
    const week = [];
    for (let date = start; date <= end; date = addDays(date, 1)) {
      week.push(new Date(date));
    }
    return week;
  }

  async function fetchEmployees() {
    const db = getFirestore();
    const usersQuery = query(collection(db, "users"));
    const querySnapshot = await getDocs(usersQuery);
    const employeesList: any[] = [];
    querySnapshot.forEach((doc) => {
      employeesList.push({ id: doc.id, ...doc.data() });
    });
    console.log(employeesList);

    setEmployees(employeesList);
  }

  async function fetchRoster() {
    if (filterDate) {
      startDate = filterDate;
      setCurrentWeek(getCurrentWeek(filterDate.toDate()));
    }

    if (!startDate) return;
    const db = getFirestore();
    const start = startOfWeek(startDate.toDate(), {
      weekStartsOn: 1,
    });
    const end = endOfWeek(startDate.toDate(), {
      weekStartsOn: 1,
    });

    const shiftsQuery = query(
      collection(db, "shifts"),
      where("date", ">=", format(start, "yyyy-MM-dd")),
      where("date", "<=", format(end, "yyyy-MM-dd"))
    );
    const querySnapshot = await getDocs(shiftsQuery);
    const data: any = {};
    querySnapshot.forEach((doc) => {
      const shiftData = doc.data();
      const day = format(new Date(shiftData.date), "EEEE");
      if (!data[day]) {
        data[day] = {};
      }
      const shiftType = shiftData.shiftType;
      if (!data[day][shiftType]) {
        data[day][shiftType] = {};
      }
      [
        "manager",
        "orders",
        "runner",
        "bar",
        "pass",
        "prep",
        "kitchen",
        "cleaner",
        "trialFloor",
        "trialKitchen",
      ].forEach((role) => {
        if (shiftData[role]) {
          data[day][shiftType][role] = shiftData[role];
        }
      });
      data[day][shiftType].id = doc.id; // Add the document ID to the data
    });
    setRoster(data);
  }

  const handleEditShift = async (date: Date, shiftType: string) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    const dayString = date.toLocaleDateString("en-GB", { weekday: "long" });

    const shiftData = roster[dayString]?.[shiftType] || {
      date: formattedDate,
      shiftType,
      manager: [{ startTime: "", endTime: "", employee: "" }],
      orders: [{ startTime: "", endTime: "", employee: "" }],
      runner: [{ startTime: "", endTime: "", employee: "" }],
      bar: [{ startTime: "", endTime: "", employee: "" }],
      pass: [{ startTime: "", endTime: "", employee: "" }],
      prep: [{ startTime: "", endTime: "", employee: "" }],
      kitchen: [{ startTime: "", endTime: "", employee: "" }],
      cleaner: [{ startTime: "", endTime: "", employee: "" }],
      trialFloor: [{ startTime: "", endTime: "", employee: "" }],
      trialKitchen: [{ startTime: "", endTime: "", employee: "" }],
    };

    shiftData.date = formattedDate;
    shiftData.shiftType = shiftType;

    // Include the id if available
    if (roster[dayString]?.[shiftType]?.id) {
      shiftData.id = roster[dayString][shiftType].id;
    }

    // Calculate total hours for each employee in the shift
    const roles = [
      "manager",
      "orders",
      "runner",
      "bar",
      "pass",
      "prep",
      "kitchen",
      "cleaner",
      "trialFloor",
      "trialKitchen",
    ];
    for (const role of roles) {
      if (Array.isArray(shiftData[role])) {
        for (const instance of shiftData[role]) {
          if (instance.employee) {
            instance.totalHours = await calculateTotalHoursForWeek(
              instance.employee,
              dayjs(formattedDate).startOf("week")
            );
          }
        }
      }
    }

    setEditingShift(shiftData);
    setNewShift(shiftData);
    setModalOpen(true);
  };

  const calculateTotalHoursForWeek = async (
    employee: string,
    weekStartDate: Dayjs
  ) => {
    const start = weekStartDate.startOf("week"); // Monday
    const end = weekStartDate.endOf("week"); // Sunday

    const db = getFirestore();
    const shiftsQuery = query(
      collection(db, "shifts"),
      where("date", ">=", format(start.toDate(), "yyyy-MM-dd")),
      where("date", "<=", format(end.toDate(), "yyyy-MM-dd"))
    );

    const querySnapshot = await getDocs(shiftsQuery);
    let totalMinutes = 0;

    querySnapshot.forEach((doc) => {
      const shiftData = doc.data();
      [
        "manager",
        "orders",
        "runner",
        "bar",
        "pass",
        "prep",
        "kitchen",
        "cleaner",
        "trialFloor",
        "trialKitchen",
      ].forEach((role) => {
        if (shiftData[role]) {
          shiftData[role].forEach((shift: any) => {
            if (shift.employee === employee) {
              const start = dayjs(shift.startTime, "HH:mm");
              const end = dayjs(shift.endTime, "HH:mm");
              totalMinutes += end.diff(start, "minute");
            }
          });
        }
      });
    });

    return (totalMinutes / 60).toFixed(2);
  };

  const handleNewShiftChange = async (
    role: string,
    index: number,
    field: string,
    value: any
  ) => {
    let totalMinutes = 0;
    const updatedShift = { ...newShift };
    if (!updatedShift[role]) {
      updatedShift[role] = [];
    }
    if (!updatedShift[role][index]) {
      updatedShift[role][index] = {};
    }
    updatedShift[role][index][field] = value;

    if (
      updatedShift[role][index].employee &&
      updatedShift[role][index].startTime &&
      updatedShift[role][index].endTime
    ) {
      const totalHours = await calculateTotalHoursForWeek(
        updatedShift[role][index].employee,
        dayjs(newShift.date).startOf("week")
      );
      const start = dayjs(updatedShift[role][index].startTime, "HH:mm");
      const end = dayjs(updatedShift[role][index].endTime, "HH:mm");
      totalMinutes += end.diff(start, "minute");

      updatedShift[role][index].totalHours =
        parseFloat(totalHours) + totalMinutes / 60;
    }

    setNewShift(updatedShift);
  };

  const addNewShiftRoleInstance = (role: string) => {
    const updatedShift = { ...newShift };
    if (!updatedShift[role]) {
      updatedShift[role] = [];
    }
    updatedShift[role].push({
      startTime: "16:00",
      endTime: "23:00",
      employee: "",
    });
    setNewShift(updatedShift);
  };

  const removeShiftRoleInstance = (role: string, index: number) => {
    const updatedShift = { ...newShift };
    if (updatedShift[role] && updatedShift[role][index]) {
      updatedShift[role].splice(index, 1);
      setNewShift(updatedShift);
    }
  };

  const handleShiftTypeChange = (shiftType: string) => {
    const updatedShift = {
      ...newShift,
      shiftType,
      manager: [{ startTime: "", endTime: "", employee: "" }],
      orders: [{ startTime: "", endTime: "", employee: "" }],
      runner: [{ startTime: "", endTime: "", employee: "" }],
      bar: [{ startTime: "", endTime: "", employee: "" }],
      pass: [{ startTime: "", endTime: "", employee: "" }],
      prep: [{ startTime: "", endTime: "", employee: "" }],
      kitchen: [{ startTime: "", endTime: "", employee: "" }],
      cleaner: [{ startTime: "", endTime: "", employee: "" }],
      trialFloor: [{ startTime: "", endTime: "", employee: "" }],
      trialKitchen: [{ startTime: "", endTime: "", employee: "" }],
    };

    if (shiftType === "Lunch") {
      updatedShift.manager.forEach((shift: any) => {
        shift.startTime = "11:00";
        shift.endTime = "16:00";
      });
      updatedShift.orders.forEach((shift: any) => {
        shift.startTime = "11:00";
        shift.endTime = "16:00";
      });
      updatedShift.runner.forEach((shift: any) => {
        shift.startTime = "11:00";
        shift.endTime = "16:00";
      });
      updatedShift.bar.forEach((shift: any) => {
        shift.startTime = "11:00";
        shift.endTime = "16:00";
      });
      updatedShift.pass.forEach((shift: any) => {
        shift.startTime = "11:00";
        shift.endTime = "16:00";
      });
      updatedShift.prep.forEach((shift: any) => {
        shift.startTime = "11:00";
        shift.endTime = "16:00";
      });
      updatedShift.kitchen.forEach((shift: any) => {
        shift.startTime = "11:00";
        shift.endTime = "16:00";
      });
      updatedShift.cleaner.forEach((shift: any) => {
        shift.startTime = "11:00";
        shift.endTime = "16:00";
      });
      updatedShift.trialFloor.forEach((shift: any) => {
        shift.startTime = "11:00";
        shift.endTime = "16:00";
      });
      updatedShift.trialKitchen.forEach((shift: any) => {
        shift.startTime = "11:00";
        shift.endTime = "16:00";
      });
    } else if (shiftType === "Dinner") {
      updatedShift.manager.forEach((shift: any) => {
        shift.startTime = "16:00";
        shift.endTime = "23:00";
      });
      updatedShift.orders.forEach((shift: any) => {
        shift.startTime = "16:00";
        shift.endTime = "23:00";
      });
      updatedShift.runner.forEach((shift: any) => {
        shift.startTime = "16:00";
        shift.endTime = "23:00";
      });
      updatedShift.bar.forEach((shift: any) => {
        shift.startTime = "16:00";
        shift.endTime = "23:00";
      });
      updatedShift.pass.forEach((shift: any) => {
        shift.startTime = "16:00";
        shift.endTime = "23:00";
      });
      updatedShift.prep.forEach((shift: any) => {
        shift.startTime = "16:00";
        shift.endTime = "23:00";
      });
      updatedShift.kitchen.forEach((shift: any) => {
        shift.startTime = "16:00";
        shift.endTime = "23:00";
      });
      updatedShift.cleaner.forEach((shift: any) => {
        shift.startTime = "16:00";
        shift.endTime = "23:00";
      });
      updatedShift.trialFloor.forEach((shift: any) => {
        shift.startTime = "16:00";
        shift.endTime = "23:00";
      });
      updatedShift.trialKitchen.forEach((shift: any) => {
        shift.startTime = "16:00";
        shift.endTime = "23:00";
      });
    }

    setNewShift(updatedShift);
  };

  const handleSaveNewShift = async () => {
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      alert("Please sign in to add shifts");
      return;
    }

    try {
      const shiftData = {
        ...newShift,
        date: dayjs(newShift.date).format("YYYY-MM-DD"),
      };

      [
        "manager",
        "orders",
        "runner",
        "bar",
        "pass",
        "prep",
        "kitchen",
        "cleaner",
        "trialFloor",
        "trialKitchen",
      ].forEach((role) => {
        // Ensure the role array is defined before mapping over it
        if (!shiftData[role]) {
          shiftData[role] = []; // Initialize as an empty array if undefined
        }

        shiftData[role] = shiftData[role].map((shift: any) => {
          if (!shift.employee) {
            return {
              ...shift,
              startTime: null,
              endTime: null,
              duration: null,
            };
          }

          const start = dayjs(shift.startTime, "HH:mm");
          const end = dayjs(shift.endTime, "HH:mm");
          const duration = Math.abs(end.diff(start, "minute"));
          return {
            ...shift,
            duration,
          };
        });
      });

      if (editingShift && editingShift.id) {
        const shiftDocRef = doc(db, "shifts", editingShift.id);
        await setDoc(shiftDocRef, shiftData);
      } else {
        await addDoc(collection(db, "shifts"), shiftData);
      }

      setModalOpen(false);
      setEditingShift(null);
      setNewShift({
        date: "",
        shiftType: "Dinner",
        manager: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
        orders: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
        runner: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
        bar: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
        pass: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
        prep: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
        kitchen: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
        cleaner: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
        trialFloor: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
        trialKitchen: [{ startTime: "16:00", endTime: "23:00", employee: "" }],
      });
      fetchRoster();
    } catch (error) {
      console.error("Error adding shift: ", error);
    }
  };

  const shouldShowShift = (shiftRoles: any) => {
    if (!showOnlyMyShifts || !firstName) return true;

    return Object.keys(shiftRoles).some(
      (role) =>
        Array.isArray(shiftRoles[role]) &&
        shiftRoles[role].some(
          (instance: any) => instance.employee === firstName
        )
    );
  };

  return (
    <main className="flex flex-col items-center p-4 min-h-screen bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Roster</h1>
      <div className="w-full max-w-6xl bg-white p-4 rounded-lg shadow-md">
        <div className="flex flex-col items-center space-y-4">
          <div className="flex items-center space-x-4">
            <WeekPicker filterDate={filterDate} setFilterDate={setFilterDate} />

            <FormControlLabel
              control={
                <Checkbox
                  checked={showOnlyMyShifts}
                  onChange={(e) => setShowOnlyMyShifts(e.target.checked)}
                  color="primary"
                />
              }
              label="Show only my shifts"
            />
          </div>

          {(role === "admin" || role === "manager") && (
            <>
              <Button
                onClick={() => setEditModalOpen(true)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Edit Graphically
              </Button>
              <button
                onClick={() => setModalOpen(true)}
                className="bg-salumeria hover:bg-salumeria-light text-white font-bold py-2 px-4 rounded"
              >
                Add New Shift
              </button>
            </>
          )}
          <button
            onClick={() => exportPDF(roster, currentWeek)}
            className="bg-salumeria hover:bg-salumeria-light text-white font-bold py-2 px-4 rounded"
          >
            Export to PDF
          </button>
        </div>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Weekly Overview
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: "100px" }}>Role</TableCell>
                {currentWeek.map((date) => {
                  const dayString = date.toLocaleDateString("en-GB", {
                    weekday: "long",
                  });
                  const lunchExists = roster[dayString]?.["Lunch"];
                  const dinnerExists = roster[dayString]?.["Dinner"];
                  return (
                    <>
                      {lunchExists &&
                        shouldShowShift(roster[dayString]?.["Lunch"]) && (
                          <TableCell
                            key={`${date}-lunch`}
                            onClick={
                              role === "admin" || role === "manager"
                                ? () => handleEditShift(date, "Lunch")
                                : undefined
                            }
                            style={
                              role === "admin" || role === "manager"
                                ? {
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    minWidth: "100px",
                                  }
                                : { minWidth: "100px" }
                            }
                          >
                            {date.toLocaleDateString("en-GB", {
                              weekday: "short",
                              day: "numeric",
                              month: "short",
                            })}{" "}
                            Lunch
                          </TableCell>
                        )}
                      {dinnerExists &&
                        shouldShowShift(roster[dayString]?.["Dinner"]) && (
                          <TableCell
                            key={`${date}-dinner`}
                            onClick={
                              role === "admin" || role === "manager"
                                ? () => handleEditShift(date, "Dinner")
                                : undefined
                            }
                            style={
                              role === "admin" || role === "manager"
                                ? {
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    minWidth: "200px",
                                  }
                                : { minWidth: "200px" }
                            }
                          >
                            {date.toLocaleDateString("en-GB", {
                              weekday: "short",
                              day: "numeric",
                              month: "short",
                            })}{" "}
                            Dinner
                          </TableCell>
                        )}
                      {!lunchExists && !dinnerExists && (
                        <TableCell
                          key={`${date}-none`}
                          onClick={
                            role === "admin" || role === "manager"
                              ? () => handleEditShift(date, "")
                              : undefined
                          }
                          style={
                            role === "admin" || role === "manager"
                              ? {
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  minWidth: "100px",
                                }
                              : { minWidth: "100px" }
                          }
                        >
                          {date.toLocaleDateString("en-GB", {
                            weekday: "short",
                            day: "numeric",
                            month: "short",
                          })}
                        </TableCell>
                      )}
                    </>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {/* Floor Roles Section */}
              <TableRow>
                <TableCell
                  colSpan={currentWeek.length + 1}
                  style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                >
                  Floor
                </TableCell>
              </TableRow>
              {["manager", "orders", "runner", "bar", "pass", "trialFloor"].map(
                (role) => (
                  <TableRow key={role}>
                    <TableCell>
                      {role === "trialFloor"
                        ? "Trial"
                        : role.charAt(0).toUpperCase() + role.slice(1)}
                    </TableCell>
                    {currentWeek.map((date) => {
                      const dayString = date.toLocaleDateString("en-GB", {
                        weekday: "long",
                      });
                      const lunchExists = roster[dayString]?.["Lunch"];
                      const dinnerExists = roster[dayString]?.["Dinner"];
                      return (
                        <>
                          {lunchExists &&
                            shouldShowShift(roster[dayString]?.["Lunch"]) && (
                              <TableCell key={`${date}-lunch`}>
                                {roster[dayString]?.["Lunch"]?.[role]?.map(
                                  (instance: any, index: number) =>
                                    instance.employee && (
                                      <Box
                                        key={index}
                                        className="border-b border-gray-200 py-1"
                                      >
                                        <Typography variant="body2">
                                          {instance.employee} -{" "}
                                          {instance.startTime} -{" "}
                                          {instance.endTime}
                                        </Typography>
                                      </Box>
                                    )
                                )}
                              </TableCell>
                            )}
                          {dinnerExists &&
                            shouldShowShift(roster[dayString]?.["Dinner"]) && (
                              <TableCell key={`${date}-dinner`}>
                                {roster[dayString]?.["Dinner"]?.[role]?.map(
                                  (instance: any, index: number) =>
                                    instance.employee && (
                                      <Box
                                        key={index}
                                        className="border-b border-gray-200 py-1"
                                      >
                                        <Typography variant="body2">
                                          {instance.employee} -{" "}
                                          {instance.startTime} -{" "}
                                          {instance.endTime}
                                        </Typography>
                                      </Box>
                                    )
                                )}
                              </TableCell>
                            )}
                          {!lunchExists && !dinnerExists && (
                            <TableCell key={`${date}-none`}>-</TableCell>
                          )}
                        </>
                      );
                    })}
                  </TableRow>
                )
              )}

              {/* Kitchen Roles Section */}
              <TableRow>
                <TableCell
                  colSpan={currentWeek.length + 1}
                  style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                >
                  Kitchen
                </TableCell>
              </TableRow>
              {["prep", "kitchen", "cleaner", "trialKitchen"].map((role) => (
                <TableRow key={role}>
                  <TableCell>
                    {role === "trialKitchen"
                      ? "Trial"
                      : role.charAt(0).toUpperCase() + role.slice(1)}
                  </TableCell>
                  {currentWeek.map((date) => {
                    const dayString = date.toLocaleDateString("en-GB", {
                      weekday: "long",
                    });
                    const lunchExists = roster[dayString]?.["Lunch"];
                    const dinnerExists = roster[dayString]?.["Dinner"];
                    return (
                      <>
                        {lunchExists &&
                          shouldShowShift(roster[dayString]?.["Lunch"]) && (
                            <TableCell key={`${date}-lunch`}>
                              {roster[dayString]?.["Lunch"]?.[role]?.map(
                                (instance: any, index: number) =>
                                  instance.employee && (
                                    <Box
                                      key={index}
                                      className="border-b border-gray-200 py-1"
                                    >
                                      <Typography variant="body2">
                                        {instance.employee} -{" "}
                                        {instance.startTime} -{" "}
                                        {instance.endTime}
                                      </Typography>
                                    </Box>
                                  )
                              )}
                            </TableCell>
                          )}
                        {dinnerExists &&
                          shouldShowShift(roster[dayString]?.["Dinner"]) && (
                            <TableCell key={`${date}-dinner`}>
                              {roster[dayString]?.["Dinner"]?.[role]?.map(
                                (instance: any, index: number) =>
                                  instance.employee && (
                                    <Box
                                      key={index}
                                      className="border-b border-gray-200 py-1"
                                    >
                                      <Typography variant="body2">
                                        {instance.employee} -{" "}
                                        {instance.startTime} -{" "}
                                        {instance.endTime}
                                      </Typography>
                                    </Box>
                                  )
                              )}
                            </TableCell>
                          )}
                        {!lunchExists && !dinnerExists && (
                          <TableCell key={`${date}-none`}>-</TableCell>
                        )}
                      </>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setEditingShift(null);
          }}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {editingShift ? "Edit Shift" : "Add New Shift"}
          </DialogTitle>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={newShift.date ? dayjs(newShift.date) : null}
                onChange={(newValue) =>
                  setNewShift({ ...newShift, date: newValue })
                }
              />
            </LocalizationProvider>
            <FormControl fullWidth margin="normal">
              <InputLabel>Shift Type</InputLabel>
              <Select
                value={newShift.shiftType}
                onChange={(e) => handleShiftTypeChange(e.target.value)}
              >
                <MenuItem value="Lunch">Lunch</MenuItem>
                <MenuItem value="Dinner">Dinner</MenuItem>
              </Select>
            </FormControl>
            <Box border={1} borderRadius={5} padding={2} marginBottom={2}>
              <Typography variant="h6" gutterBottom>
                Floor
              </Typography>
              {["manager", "orders", "runner", "bar", "pass", "trialFloor"].map(
                (role) => (
                  <div key={role}>
                    <Typography variant="subtitle1">
                      {role === "trialFloor"
                        ? "Trial"
                        : role.charAt(0).toUpperCase() + role.slice(1)}
                      <IconButton
                        onClick={() => addNewShiftRoleInstance(role)}
                        size="small"
                      >
                        <AddIcon />
                      </IconButton>
                    </Typography>
                    {Array.isArray(newShift[role]) ? (
                      newShift[role].map((shift: any, index: number) => (
                        <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                          <Grid item xs={4}>
                            <TextField
                              label="Start Time"
                              type="time"
                              fullWidth
                              value={shift.startTime}
                              onChange={(e) =>
                                handleNewShiftChange(
                                  role,
                                  index,
                                  "startTime",
                                  e.target.value
                                )
                              }
                              margin="normal"
                              inputProps={{
                                step: 900, // 15 minutes
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="End Time"
                              type="time"
                              fullWidth
                              value={shift.endTime}
                              onChange={(e) =>
                                handleNewShiftChange(
                                  role,
                                  index,
                                  "endTime",
                                  e.target.value
                                )
                              }
                              margin="normal"
                              inputProps={{
                                step: 900, // 15 minutes
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            {role === "trialFloor" ||
                            role === "trialKitchen" ? (
                              <TextField
                                label="Trial Employee Name"
                                fullWidth
                                value={shift.employee || ""}
                                onChange={(e) =>
                                  handleNewShiftChange(
                                    role,
                                    index,
                                    "employee",
                                    e.target.value
                                  )
                                }
                                margin="normal"
                              />
                            ) : (
                              <FormControl fullWidth margin="normal">
                                <InputLabel>Employee</InputLabel>
                                <Select
                                  value={
                                    employees.some(
                                      (emp) => emp.firstName === shift.employee
                                    )
                                      ? shift.employee
                                      : ""
                                  }
                                  onChange={async (e) => {
                                    handleNewShiftChange(
                                      role,
                                      index,
                                      "employee",
                                      e.target.value
                                    );
                                    const totalHours =
                                      await calculateTotalHoursForWeek(
                                        e.target.value,
                                        dayjs(newShift.date)
                                      );
                                    handleNewShiftChange(
                                      role,
                                      index,
                                      "totalHours",
                                      totalHours
                                    );
                                  }}
                                >
                                  {employees
                                    .filter((emp) => emp.category === "floor")
                                    .map((employee) => (
                                      <MenuItem
                                        key={employee.id}
                                        value={employee.firstName}
                                      >
                                        {employee.firstName}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {shift.totalHours && (
                              <Typography variant="body2" color="textSecondary">
                                {shift.employee} will work {shift.totalHours}{" "}
                                hours this week.
                              </Typography>
                            )}
                            <IconButton
                              onClick={() =>
                                removeShiftRoleInstance(role, index)
                              }
                              size="small"
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        No shifts assigned for {role}.
                      </Typography>
                    )}
                  </div>
                )
              )}
            </Box>
            <Box border={1} borderRadius={5} padding={2} marginBottom={2}>
              <Typography variant="h6" gutterBottom>
                Kitchen
              </Typography>
              {["prep", "kitchen", "cleaner", "trialKitchen"].map((role) => (
                <div key={role}>
                  <Typography variant="subtitle1">
                    {role === "trialKitchen"
                      ? "Trial"
                      : role.charAt(0).toUpperCase() + role.slice(1)}
                    <IconButton
                      onClick={() => addNewShiftRoleInstance(role)}
                      size="small"
                    >
                      <AddIcon />
                    </IconButton>
                  </Typography>
                  {Array.isArray(newShift[role]) ? (
                    newShift[role].map((shift: any, index: number) => (
                      <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                        <Grid item xs={4}>
                          <TextField
                            label="Start Time"
                            type="time"
                            fullWidth
                            value={shift.startTime}
                            onChange={(e) =>
                              handleNewShiftChange(
                                role,
                                index,
                                "startTime",
                                e.target.value
                              )
                            }
                            margin="normal"
                            inputProps={{
                              step: 900, // 15 minutes
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="End Time"
                            type="time"
                            fullWidth
                            value={shift.endTime}
                            onChange={(e) =>
                              handleNewShiftChange(
                                role,
                                index,
                                "endTime",
                                e.target.value
                              )
                            }
                            margin="normal"
                            inputProps={{
                              step: 900, // 15 minutes
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          {role === "trialFloor" || role === "trialKitchen" ? (
                            <TextField
                              label="Trial Employee Name"
                              fullWidth
                              value={shift.employee || ""}
                              onChange={(e) =>
                                handleNewShiftChange(
                                  role,
                                  index,
                                  "employee",
                                  e.target.value
                                )
                              }
                              margin="normal"
                            />
                          ) : (
                            <FormControl fullWidth margin="normal">
                              <InputLabel>Employee</InputLabel>
                              <Select
                                value={
                                  employees.some(
                                    (emp) => emp.firstName === shift.employee
                                  )
                                    ? shift.employee
                                    : ""
                                }
                                onChange={async (e) => {
                                  handleNewShiftChange(
                                    role,
                                    index,
                                    "employee",
                                    e.target.value
                                  );
                                  const totalHours =
                                    await calculateTotalHoursForWeek(
                                      e.target.value,
                                      dayjs(newShift.date)
                                    );
                                  handleNewShiftChange(
                                    role,
                                    index,
                                    "totalHours",
                                    totalHours
                                  );
                                }}
                              >
                                {employees
                                  .filter((emp) => emp.category === "kitchen")
                                  .map((employee) => (
                                    <MenuItem
                                      key={employee.id}
                                      value={employee.firstName}
                                    >
                                      {employee.firstName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {shift.totalHours && (
                            <Typography variant="body2" color="textSecondary">
                              {shift.employee} will work {shift.totalHours}{" "}
                              hours this week.
                            </Typography>
                          )}
                          <IconButton
                            onClick={() => removeShiftRoleInstance(role, index)}
                            size="small"
                          >
                            <RemoveIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No shifts assigned for {role}.
                    </Typography>
                  )}
                </div>
              ))}
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setModalOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSaveNewShift} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <RosterEditModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          roster={roster}
          setRoster={setRoster}
          employees={employees}
          currentWeek={currentWeek}
        />
      </div>
    </main>
  );
};

export default Roster;
